<template>
  <v-fade-transition>
    <v-btn v-show="show || loading || $vuetify.breakpoint.smAndDown"
           small
           elevation="5"
           :loading="loading"
           class="white export-btn"
           :class="needsExtraSpaceOnTop ? 'extra-space-on-top' : ''"
           @click="exportImage"
           icon
           color="primary">
      <v-icon size="20">lnr lnr-camera</v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: "ExportImageButton",
  props: ['options', 'needsExtraSpaceOnTop', 'show'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async exportImage() {
      this.loading = true;
      try {
        const result = await tenantApi.takeScreenshot(this.options);
        this.$downloadAsImage(result);
      } catch (error) {
        console.error(error);
        this.$showErrorNotification(this.$t('errors.error_taking_screenshot'));
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  &.extra-space-on-top {
    top: 30px;
  }
}
</style>
