const sdgs = {
  1: {
    text: 'No poverty',
    color: '#E5243B',
    goalText: 'To end poverty in all its forms everywhere',
  },
  2: {
    text: 'Zero hunger',
    color: '#DDA63A',
    goalText: 'To end hunger, achieve food security and improved nutrition and promote sustainable agriculture',
  },
  3: {
    text: 'Good health and well-being',
    color: '#4C9F38',
    goalText: 'To ensure healthy lives and promote well-being for all at all ages',
  },
  4: {
    text: 'Quality education',
    color: '#C5192D',
    goalText: 'To ensure inclusive and equitable quality education and promote lifelong learning opportunities for all',
  },
  5: {
    text: 'Gender equality',
    color: '#FF3A21',
    goalText: 'To achieve gender equality and empower all women and girls',
  },
  6: {
    text: 'Clean water and sanitation',
    color: '#26BDE2',
    goalText: 'To ensure availability and sustainable management of water and sanitation for all ',
  },
  7: {
    text: 'Affordable and clean energy',
    color: '#FCC30B',
    goalText: 'To ensure access to affordable, reliable, sustainable and modern energy for all',
  },
  8: {
    text: 'Decent work and economic growth',
    color: '#A21942',
    goalText: 'To promote sustained, inclusive and economic growth, full and productive employment and decent work for all',
  },
  9: {
    text: 'Industry, innovation and infrastructure',
    color: '#FD6925',
    goalText: 'To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
  },
  10: {
    text: 'Reduced inequalities',
    color: '#DD1367',
    goalText: 'To reduce inequality within and among countries',
  },
  11: {
    text: 'Sustainable cities and communities',
    color: '#FD9D24',
    goalText: 'To make cities and human settlements inclusive, safe, resilient and sustainable',
  },
  12: {
    text: 'Responsible consumption and production',
    color: '#BF8B2E',
    goalText: 'To ensure sustainable consumption and production patterns',
  },
  13: {
    text: 'Climate action',
    color: '#3F7E44',
    goalText: 'To take urgent action to combat climate change and its impacts',
  },
  14: {
    text: 'Life below water',
    color: '#0A97D9',
    goalText: 'To conserve and sustainably use the oceans, seas and marine resources for sustainable development',
  },
  15: {
    text: 'Life on land',
    color: '#56C02B',
    goalText: 'To protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
  },
  16: {
    text: 'Peace, justice and strong institutions',
    color: '#00689D',
    goalText: 'To promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels',
  },
  17: {
    text: 'Partnerships for the goals',
    color: '#19486A',
    goalText: 'To Strengthen the means of implementation and revitalize the global partnership for sustainable development',
  },
}

const businesses = [
  {
    id: 0,
    text: 'Communication, media',
  }, {
    id: 1,
    text: 'Consumer Goods',
  }, {
    id: 2,
    text: 'Energy',
  }, {
    id: 3,
    text: 'E-Commerce',
  }, {
    id: 4,
    text: 'Events',
  }, {
    id: 5,
    text: 'Digital Services',
  }, {
    id: 6,
    text: 'Finance',
  }, {
    id: 7,
    text: 'Horeca',
  }, {
    id: 8,
    text: 'Industry',
  }, {
    id: 9,
    text: 'Information Technology',
  }, {
    id: 10,
    text: 'Health Care',
  }, {
    id: 11,
    text: 'Materials',
  }, {
    id: 12,
    text: 'Real Estate',
  }, {
    id: 13,
    text: 'Software',
  }, {
    id: 14,
    text: 'Technology',
  }, {
    id: 15,
    text: 'Textiles',
  },
]

const headcount = [
  {
    id: 0,
    text: '0-10',
  },
  {
    id: 1,
    text: '11-50'
  },
  {
    id: 2,
    text: '51-250'
  },
  {
    id: 3,
    text: 'over 250',
  }
]

const turnover = [
  {
    id: 0,
    text: 'up to 2 MEUR',
  },
  {
    id: 1,
    text: '2 to 10 MEUR',
  },
  {
    id: 2,
    text: '10 to 50 MEUR',
  },
  {
    id: 3,
    text: 'over 50 MEUR',
  },
]

const esgs = [
  {
    text: 'Environment',
    color: '#439986',
  },
  {
    text: 'Social',
    color: '#43959f',
  },
  {
    text: 'Governance',
    color: '#133549',
  },
]

const progressChartColors = {
  0: { color: '#E5243B' },
  1: { color: '#DDA63A' },
  2: { color: '#4C9F38' },
  3: { color: '#C5192D' },
  4: { color: '#FF3A21' },
  5: { color: '#26BDE2' },
  6: { color: '#FCC30B' },
  7: { color: '#A21942' },
  8: { color: '#FD6925' },
  9: { color: '#DD1367' },
  10: { color: '#FD9D24' },
  11: { color: '#BF8B2E' },
  12: { color: '#3F7E44' },
  13: { color: '#0A97D9' },
  14: { color: '#56C02B' },
  15: { color: '#00689D' },
  16: { color: '#19486A' },
  17: { color: '#439986' },
  18: { color: '#43959f' },
  19: { color: '#133549' },
}

export default {
  sdgs,
  businesses,
  headcount,
  turnover,
  esgs,
  progressChartColors,
}
