<template>
  <v-row>

    <v-col cols="12">
      <hb-heading v-model="title" :subtitle="$tc('dashboards.title', 1)" :editable="editable" @input="titleChanged"></hb-heading>
    </v-col>

    <v-col cols="12" v-if="action.containsData">
      <v-row align="stretch">
        <v-col cols="12" sm="4" xl="3">
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-card class="sdg-card rounded-0 text-center white" id="current-value-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
                <ExportImageButton v-if="editable" :needsExtraSpaceOnTop="true" :show="showExportButtons" :options="{dashboardCode: this.settings.code, actionId: action.id, elementId: '#current-value-card'}" />
                <v-card-title class="sdg-card-title" style="display: block;">
                  <div>
                    <div class="sdg-date-title">{{ $formatDateWithMonthName(action.currentValue.date) }}</div>
                    <div class="sdg-subtitle">{{ $t('dashboards.current_value') }}</div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <div class="sdg-value-text sdg-green--text">{{ action.currentValue.value }} {{ action.unit }}</div>
                  <div class="sdg-caption">{{ $t('dashboards.target_value') }} {{ action.targetValue }} {{ action.unit }}</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-card class="sdg-card blue-border rounded-0 text-center" id="growth-rate-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
                <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, actionId: action.id, elementId: '#growth-rate-card'}" />
                <v-card-title class="sdg-card-title" style="display: block;">
                  <div>
                    <div class="sdg-subtitle">{{ $t('dashboards.growth_rate_today') }}</div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <div class="sdg-value-text" :class="currentGrowthRate < goalGrowthRate ? 'sdg-red--text' : 'sdg-green--text'">{{ currentGrowthRate }} {{ action.unit }}</div>
                  <div class="sdg-value-caption"> / {{ currentGrowthTimeSpanText }}</div>
                  <div class="sdg-caption">{{ $t('dashboards.my_goal') }} {{ goalGrowthRate }} {{ action.unit }} / {{ currentGrowthTimeSpanText }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="8" xl="9">
          <v-card class="sdg-card blue-border rounded-0 fill-height" id="action-info-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
            <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, actionId: action.id, elementId: '#action-info-card'}" />
            <v-card-text class="sdg-card-text">
              <v-row>
                <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10'">
                  <div class="sdg-heading-2" @click="toSDGDashboard(action.sdgIndex)" style="cursor: pointer">
                    SDG{{ action.sdgIndex }}
                  </div>
                  <div class="sdg-subtitle mb-5">
                    {{ $sdg(action.sdgIndex).goalText }}
                  </div>
                  <v-img max-height="100px" max-width="100px" :src="$getSDGImage(action.sdgIndex)" @click="toSDGDashboard(action.sdgIndex)"  style="cursor: pointer"></v-img>
                </v-col>
                <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10'">
                  <div class="sdg-subtitle-2">{{ $t('dashboards.sdg_business_indicator') }}</div>
                  <div class="mb-3">{{ action.indicatorDescription }}</div>
                  <div><span class="sdg-label">SDG ind:</span> {{ action.indicatorCode }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.start') }}:</span> {{ $formatDateWithMonthName(action.startDate) }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.end') }}:</span> {{ $formatDateWithMonthName(action.endDate) }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.start_value') }}:</span> {{ action.startValue }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.target_value') }}:</span> {{ action.targetValue }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.detailed_impact') }}:</span> {{ action.impactText }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.esg') }}:</span> {{ action.esg }}</div>
                  <div><span class="sdg-label">{{ $t('dashboards.detailed_esg') }}:</span> {{ $detailedEsgText(action.esg) }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="action.containsData">
      <v-card class="sdg-card rounded-0 no-border" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10'"  id="action-performance-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, actionId: action.id, elementId: '#action-performance-card'}" />
        <v-card-text>
          <div class="sdg-heading-2">{{ $t('dashboards.action_performance') }}</div>
          <div id="chartContainer"></div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="8" offset-md="2" v-if="!action.containsData">
      <v-alert type="info" :value="true" prominent text>
        {{ $t('dashboards.action_does_not_contain_data') }}
        <v-row no-gutters justify="start" class="mt-2">
          <v-btn color="info" small @click="goToPlan" outlined>{{ $t('dashboards.go_to_plan') }}</v-btn>
        </v-row>
      </v-alert>
    </v-col>


  </v-row>
</template>

<script>
  import dayjs from "dayjs";
  import * as math from "mathjs";
  import _ from 'lodash';
  import Highcharts from "highcharts";
  import ExportImageButton from "@/components/ExportImageButton";

  export default {
    name: 'ActionDashboard',
    components: {ExportImageButton},
    props: ['action', 'settings', 'editable'],
    data() {
      return {
        title: '',
        showExportButtons: false,
      }
    },
    watch: {
      settings() {
        this.setTitle();
      },
    },
    methods: {
      goToPlan() {
        this.$router.push({name: 'plan', params: { planId: this.action.planId, actionId: this.action.id }});
      },
      toSDGDashboard(sdgIndex) {
        if (this.$router == null) {
          return;
        }

        const data = { name: 'sdg_dashboard', params: { sdgIndex: sdgIndex, tenantId: this.$tenant, planId: this.action.planId }};
        this.$router.push(data);
      },
      titleChanged() {
        this.$emit('title-changed', this.title);
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = `${this.$tc('dashboards.action', 1)}: ${this.action.description}`;
        } else {
          this.title = this.settings.title;
        }
      },
      createChart() {
        const targetSerie = {
          data: [[dayjs(this.action.startDate).unix() * 1000, this.action.startValue], [dayjs(this.action.endDate).unix() * 1000, this.action.targetValue]],
          dashStyle: 'longdash',
          name: 'Target',
          // enableMouseTracking: false,
        };

        const values = [];

        const dataPoints = [...this.action.dataPoints];
        dataPoints.push({
          type: 'ABSOLUTE',
          date: this.action.startDate,
          value: this.action.startValue,
        });

        const sortedDataPoints = _.sortBy(dataPoints, 'date');

        let cumulative = 0;

        let lastDP = null;
        sortedDataPoints.forEach(dp => {
          if (dp.type === 'CUMULATIVE') {
            cumulative = math.sum(cumulative, dp.value);
          } else if (dp.type === 'ABSOLUTE') {
            cumulative = dp.value;
          }

          const mom = dayjs(dp.date);
          const momMillis = mom.unix() * 1000;

          lastDP = {date: dp.date, value: cumulative };
          values.push([momMillis, cumulative]);
        });


        let maxDate = null;
        let text = '';
        if (dayjs().isBefore(dayjs(this.action.endDate))) {
          maxDate = dayjs();
          text = 'Current date value';
        } else {
          maxDate = dayjs(this.action.endDate);
          text = 'Final value';
        }


        const toCurrentDateValues = [];
        if (lastDP != null && dayjs(lastDP.date).isBefore(maxDate)) {
          toCurrentDateValues.push([dayjs(lastDP.date).unix() * 1000, cumulative]);
          toCurrentDateValues.push([maxDate.unix() * 1000, cumulative]);
        }

        const toCurrentDateSerie = {
          color: this.$sdg(this.action.sdgIndex).color,
          name: text,
          dashStyle: 'dot',
          data: toCurrentDateValues,
          zIndex: 500,
        }

        const currentSerie = {
          color: this.$sdg(this.action.sdgIndex).color,
          name: this.action.indicatorCode,
          data: values,
          zIndex: 1000,
        }

        this.chart = Highcharts.chart('chartContainer', {
          title: {
            text: ''
          },
          credits: false,
          plotOptions: {
            series: {
              marker: {
                enabledThreshold: 1.5
              }
            }
          },
          yAxis: {
            title: '',
            labels: {
              style: {
                fontSize: '16px',
                color: 'black'
              },
            },

          },
          xAxis: {
            type: 'datetime',
            labels: {
              style: {
                fontSize: '16px',
                color: 'black'
              },
            },
          },
          legend: {
            itemStyle: {
              fontFamily: 'forma-djr-banner, Tahoma',
              fontWeight: 'normal',
              fontSize: '18px'
            }
          },
          chart: {
            backgroundColor: 'transparent',
            height: '300px',
            style: {
              fontFamily: 'forma-djr-banner, Tahoma',
              fontSize: '18px'
            },
          },
          series: [currentSerie, targetSerie, toCurrentDateSerie]
        });
      }
    },
    computed: {
      currentGrowthTimeSpan() {
        const action = this.action;
        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const momentDuration = dayjs.duration(duration);
        if (momentDuration.asMonths() < 1)
          return 'day'
        if (momentDuration.asMonths() <= 24)
          return 'month';

        return 'year'
      },
      currentGrowthTimeSpanText() {
        return this.currentGrowthTimeSpan;
      },
      goalGrowthRate() {
        const action = this.action;
        if (action.currentValue == null) {
          return 0;
        }

        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const timeUnits = dayjs.duration(duration).as(this.currentGrowthTimeSpan);

        const value = math.evaluate('(endValue - startValue) / timeUnits', { endValue: action.targetValue, startValue: action.startValue, timeUnits });
        return math.round(value, 1);
      },
      currentGrowthRate() {
        const action = this.action;

        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const totalTimeUnits = dayjs.duration(duration).as(this.currentGrowthTimeSpan);

        let lastDataMoment = dayjs(this.action.currentValue.date);
        if (endMoment.isBefore(dayjs()) && lastDataMoment.isBefore(endMoment)) {
          lastDataMoment = endMoment.startOf('d');
        }

        if (endMoment.isAfter(dayjs()) && lastDataMoment.isBefore(dayjs())) {
          lastDataMoment = dayjs().startOf('d');
        }

        const timeUnits = dayjs.duration(lastDataMoment.diff(startMoment)).as(this.currentGrowthTimeSpan);

        const currentTimeUnits = math.max(timeUnits, 1);

        const scope = { currentValue: action.currentValue.value, totalTimeUnits, currentTimeUnits, startValue: action.startValue };
        const value = math.evaluate('(currentValue - startValue) / (currentTimeUnits)', scope);

        if (isNaN(value)) {
          return 0;
        }

        return math.round(value, 1);
      },
    },
    mounted() {
      this.setTitle();
      if (this.action.containsData) {
        this.createChart();
      }
    }
  }
</script>

<style scoped>

</style>
