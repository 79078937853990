import '@mdi/font/css/materialdesignicons.css';
import '../../public/linearicons/style.css'; // Check icons from https://linearicons.com/free#cdn
import '../../public/fontawesome/css/all.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'lnr',
    values: {
      complete: 'lnr lnr-checkmark-circle',
      cancel: 'lnr lnr-cross-circle',
      close: 'lnr lnr-cross',
      delete: 'lnr lnr-cross-circle', // delete (e.g. v-chip close)
      clear: 'lnr lnr-cross',
      success: 'lnr lnr-checkmark-circle',
      info: 'lnr lnr-bullhorn',
      warning: 'lnr lnr-warning',
      error: 'lnr lnr-warning',
      prev: 'lnr lnr-chevron-left',
      next: 'lnr lnr-chevron-right',
      sort: 'lnr lnr-arrow-up',
      expand: 'lnr lnr-chevron-down',
      menu: 'lnr lnr-menu',
      edit: 'lnr lnr-pencil',
      ratingEmpty: 'lnr lnr-star-empty',
      ratingFull: 'lnr lnr-star',
      ratingHalf: 'lnr lnr-star-half',
      loading: 'lnr lnr-sync',
      file: 'lnr lnr-paperclip',
      plus: 'lnr lnr-plus-circle',
      minus: 'lnr lnr-circle-minus',
      checkboxOn: 'check_box',
      checkboxOff: 'check_box_outline_blank',
      checkboxIndeterminate: 'indeterminate_check_box',
      delimiter: 'fiber_manual_record', // for carousel
      subgroup: 'arrow_drop_down',
      dropdown: 'arrow_drop_down',
      radioOn: 'radio_button_checked',
      radioOff: 'radio_button_unchecked',
      first: 'first_page',
      last: 'last_page',
      unfold: 'unfold_more',
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#59949d',
        secondary: '#8ab4ba',
        accent: '#834652',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      },
      dark: {
        primary: '#59949d',
        secondary: '#8ab4ba',
        accent: '#834652',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      }
    }
  }
});
