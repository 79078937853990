<template>
  <div>
    <strong>{{ pieData.key }}</strong>
    <div v-if="actionsWithData.length > 0">
      Overall index {{ point.z }}
    </div>
    <div v-else>
      No data yet
    </div>
    <table>
      <thead>
      <th>
        Indicator
      </th>
      <th class="pl-2">
        Actions
      </th>
      <th class="pl-5">
        Index
      </th>
      </thead>
      <tbody>
      <tr v-for="action in actionsByIndicatorCode" :key="action.code">
        <td>
          {{ action.code }}
        </td>
        <td class="text-center pl-2">
          {{ action.count }}
        </td>
        <td class="text-center pl-5">
          <template v-if="action.hasData">{{ action.index }}</template>
          <template v-else>No data yet</template>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import _ from 'lodash';
import * as math from 'mathjs';

export default {
  props: ['chart', 'pieData'],
  mounted() {
  },
  computed: {
    actionsWithData() {
      return this.point.actions.filter(a => a.containsData);
    },
    point() {
      return this.pieData.point;
    },
    actionsByIndicatorCode() {
      const grouped = _.groupBy(this.point.actions, 'indicatorCode');

      return Object.keys(grouped).map(key => {
        const actionForKey = grouped[key];
        const indices = actionForKey.map(a => this.$calculateSDGIndexPretty(a));
        return {
          code: key,
          hasData: actionForKey.filter(a => a.containsData).length > 0,
          count: actionForKey.length,
          index: math.round(math.mean(indices)),
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
