<template>
  <v-row>

    <v-col cols="12">
      <hb-heading :editable="editable" v-model="title"
                  @input="titleChanged" :subtitle="$tc('dashboards.title', 1)"></hb-heading>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="sdg-card blue-border rounded-0 fill-height" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10 pt-0'" id="sdgs-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :show="showExportButtons" :options="{ dashboardCode: this.settings.code, elementId: '#sdgs-card'}" />
        <v-card-text>
          <div class="sdg-heading-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-10' : ''">{{ $t('dashboards.sdgs') }}</div>
          <AllSDGsRadiusPie :actions="actions" v-if="actions.length > 0"></AllSDGsRadiusPie>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="sdg-card blue-border rounded-0 fill-height" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10 pt-0'" id="esg-impacts-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :show="showExportButtons" :options="{ dashboardCode: this.settings.code, elementId: '#esg-impacts-card'}" />
        <v-card-text>
          <div class="sdg-heading-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-10' : ''">{{ $t('dashboards.esg_impacts') }}</div>
          <EsgImpactPie v-if="actions.length > 0" :actions="actions"></EsgImpactPie>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="12" v-if="showLongTermDashboard">
      <v-card class="sdg-card blue-border rounded-0 fill-height" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10 pt-0'" id="long-term-performance-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :show="showExportButtons" :options="{ dashboardCode: this.settings.code, elementId: '#long-term-performance-card'}" />
        <v-card-text>
          <div class="sdg-heading-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-10' : ''">{{ $t('dashboards.long_term_perf') }}</div>
          <template v-if="editable !== false && yearsWithMonths.length > 0" >
            <v-select :items="years" v-model="selectedLongTermYear" :label="$t('dashboards.start_year')" filled class="rounded-0 ignore-export">
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="saveLongTermChange" :disabled="!longTermYearChanged" style="margin-top: -12px;">
                      <v-icon color="primary">save</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('dashboards.save_as_default')}}</span>
                </v-tooltip>
              </template>
            </v-select>
          </template>
          <LongTermProgressChart :actions="actionsWithData" v-if="actionsWithData.length > 0" :settings="settings" :selected-year="selectedLongTermYear"></LongTermProgressChart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash';
  import dayjs from "dayjs";
  const localeData = require('dayjs/plugin/localeData')
  dayjs.extend(localeData)
  dayjs().localeData()

  import AllSDGsRadiusPie from "../Charts/AllSDGsRadiusPie";
  import EsgImpactPie from "../Charts/EsgImpactPie";
  import LongTermProgressChart from "../Charts/LongTermProgressChart";
  import ExportImageButton from "@/components/ExportImageButton";

  export default {
    props: ['actions', 'plan', 'settings', 'editable'],
    name: 'OverviewDashboard',
    components: {ExportImageButton, LongTermProgressChart, AllSDGsRadiusPie, EsgImpactPie },
    data() {
      return {
        previousSelectedLongTermYear: null,
        selectedLongTermYear: null,
        title: '',
        yearsWithMonths: [],
        yearMonths: [],
        showExportButtons: false,
      }
    },
    watch: {
      settings() {
        this.setTitle();
      },
    },
    methods: {
      titleChanged() {
        this.$emit('title-changed', this.title);
      },
      saveLongTermChange() {
        this.$emit('long-term-saved', this.selectedLongTermYear);
        this.previousSelectedLongTermYear = this.selectedLongTermYear;
      },
      setupData() {
        const years = [];

        this.actionsWithData.forEach(a => {
          a.dataPoints.forEach(dp => {
            const dpMoment = dayjs(dp.date);
            const year = dpMoment.year();
            const existingYear = years.find(y => y.year === year);
            let month = dpMoment.month();
            if (existingYear == null) {
              years.push({
                year: year,
                months: [month],
              });
            } else {
              if (existingYear.months.find(m => m === month) == null) {
                existingYear.months.push(month);
              }
            }
          });
        });

        this.yearsWithMonths = years;
        if (this.years.length > 0) {
          this.selectedLongTermYear = this.years.find(y => y === this.previousSelectedLongTermYear) || _.min(this.years);
        }
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = `${this.$t('dashboards.overview')}`;
        } else {
          this.title = this.settings.title;
        }
      },
    },
    computed: {
      actionsWithData() {
        return this.actions.filter(a => a.containsData);
      },
      showLongTermDashboard() {
        if (this.actions && this.actions.length > 20) return false;
        return this.yearsWithMonths.length > 0;
      },
      years() {
        const years = this.yearsWithMonths.map(y => y.year);
        years.sort();
        return years;
      },
      longTermYearChanged() {
        return this.previousSelectedLongTermYear !== this.selectedLongTermYear;
      },
    },
    mounted() {
      const meta = this.settings.meta;
      if (meta != null && 'long.year' in meta) {
        this.previousSelectedLongTermYear = meta["long.year"];
      }

      this.setupData();
      this.setTitle();
    }
  }
</script>

<style scoped>

</style>
