<template>
  <v-row :no-gutters="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? 'mb-3' : ''" @click="navigateToAction" style="cursor: pointer">
    <!-- Large screens -->
    <template v-if="$vuetify.breakpoint.lgAndUp">
      <v-col cols="2" class="font-weight-medium">
        {{ action.description }}
      </v-col>
      <v-col cols="2" :class="statusTextColor">
        {{ action.containsData ? $t('plan.action_measuring_started') : $t('plan.action_no_data_yet') }}
      </v-col>
      <v-col cols="3">
        {{ action.indicatorDescription }}
      </v-col>
      <v-col cols="2" class="text-no-wrap">
        {{ action.esg }}
      </v-col>
      <v-col cols="3" class="">
        {{ action.impactText }}
      </v-col>
    </template>

    <!-- Small screens -->
    <template v-if="$vuetify.breakpoint.mdAndDown">
      <v-col cols="7" class="font-weight-medium pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $tc('dashboards.action', 1) }}
        </div>
        {{ action.description }}
      </v-col>
      <v-col cols="6" sm="12" class="caption pa-1 grey--text" style="line-height: 1rem;">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.status') }}
        </div>
        <span :class="statusTextColor">{{ action.containsData ? $t('plan.action_measuring_started') : $t('plan.action_no_data_yet') }}</span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.sdg_business_indicator') }}
        </div>
        {{ action.indicatorDescription }}
      </v-col>
      <v-col cols="12" class="pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.esg') }}
        </div>
        {{ action.esg }}
      </v-col>
      <v-col cols="12" class="pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.detailed_esg')}}
        </div>
        {{ detailedEsg }}
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="caption pa-1 grey--text" style="line-height: 1rem;">Target date:<br v-if="$vuetify.breakpoint.xsOnly" /> {{ targetDate }}</v-col>
    </template>
  </v-row>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'IndicatorTableActionItem',
  props: ['action'],
  methods: {
    navigateToAction() {
      if (this.$router != null) {
        this.$router.push({ name: 'action_dashboard', params: { actionId: this.action.id, planId: this.action.planId } })
      }
    }
  },
  computed: {
    statusTextColor() {
      return this.action.containsData ? 'green--text' : 'red--text';
    },
    detailedEsg() {
      return this.$detailedEsgText(this.action.esg);
    },
    currentDate() {
      if (!this.action.currentValue) return false;
      return dayjs(this.action.currentValue.date).format('MMM-YYYY').toUpperCase();
    },
    targetDate() {
      if (!this.action.endDate) return false;
      return dayjs(this.action.endDate).format('MMM-YYYY').toUpperCase();
    },
  },
}
</script>

<style lang="scss" scoped>
.stagnating {
  transform-origin: center;
  transform: rotate(90deg);
}

.moderately_improving {
  transform-origin: center;
  transform: rotate(45deg);
}

.action_needed {
  transform-origin: center;
  transform: rotate(135deg);
}
</style>
