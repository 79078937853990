<template>
  <div>
    <strong>{{ point.name }}</strong>
    <br />
    <div>{{ esgText }}</div>
    <br />
    <div>Amount of actions: {{ actionCount }}</div>
    <br />
    <strong>Actions</strong>
    <table>
      <tbody>
        <tr v-for="(action, i) in actions" :key="i">
          <td>
            {{ action.description }}, {{ action.indicatorCode }}
          </td>
        </tr>
        <tr v-if="andMoreCount > 0">
          <td class="grey--text text--darken-1">
            And {{ andMoreCount }} more...
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: ['chart', 'pieData'],
  mounted() {

  },
  computed: {
    point() {
      return this.pieData.point;
    },
    actions() {
      return this.pieData.point.actions.slice(0, 10);
    },
    actionCount() {
      return this.pieData.y;
    },
    andMoreCount() {
      return this.pieData.y - 10;
    },
    esgText() {
      const esg = this.point.name;
      if (esg === 'Governance')
        return 'Help business to grow in a just and transparent way.';
      if (esg === 'Environment')
        return 'Help business to grow without harming the environment.';

      return 'Help business to grow while enhancing the social well-being.';
    }
  },
}
</script>

<style scoped>

</style>
