<template>
  <v-row dense>
    <v-col cols="12" class="mb-6">
      <div class="sdg-subtitle">{{ $t('dashboards.custom_impacts.action_count') }}: {{ actionsCount.length }}</div>
    </v-col>
    <v-col cols="12">
      <v-list color="transparent" class="py-0 px-0">
        <template v-if="$vuetify.breakpoint.lgAndUp"> <!-- Header for large screens -->
          <v-list-item dense class="px-0">
            <v-list-item-content class="align-self-start align-items-start py-2">
              <v-list-item-subtitle class="caption text-wrap">
                <v-row class="secondary--text" dense>
                  <v-col :cols="cols">
                    {{ $tc('dashboards.action', 1) }}
                  </v-col>
                  <v-col :cols="cols">
                    {{ $t('dashboards.status')}}
                  </v-col>
                  <v-col :cols="cols">
                    {{ $t('dashboards.custom_impacts.impact')}}
                  </v-col>
                  <v-col cols="3" v-if="hasDetailedImpacts">
                    {{ $t('dashboards.custom_impacts.detailed_impact')}}
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-for="action in actionsFiltered" :key="action.id" class="align-items-start px-0">
          <v-list-item-content class="align-self-start align-items-start py-2">
            <v-list-item-subtitle class="align-self-start text-wrap">
              <custom-impact-action-list-item :root-impact="rootImpact" :action="action" :customImpacts="customImpacts">
              </custom-impact-action-list-item>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col cols="12" lg="6" v-for="action in actionsFiltered" :key="action.id" >

    </v-col>
  </v-row>
</template>

<script>

import CustomImpactActionListItem from "@/components/CustomImpactDashboard/CustomImpactActionListItem";
import _ from "lodash";
export default {
  components: { CustomImpactActionListItem },
  props: ['rootImpact', 'customImpacts', 'dashboardData'],
  name: 'CustomImpactActionList',
  computed: {
    cols() {
      return this.hasDetailedImpacts ? 3 : 4;
    },
    hasDetailedImpacts() {
      return this.customImpacts.find(ci => ci.root === this.rootImpact.id && ci.level === 2);
    },
    actions() {
      return this.dashboardData.actions;
    },
    actionsCount() {
      return this.dashboardData.actions.filter(a => a.customImpacts.find(aci => {
        const spec = this.customImpacts.find(ciSpec => ciSpec.id === aci.id);
        return spec.root === this.rootImpact.id;
      }));
    },
    actionsFiltered() {
      if (!this.actions) {
        return []
      }

      const actions = this.actions.filter(a => a.customImpacts.find(aci => {
        const spec = this.customImpacts.find(ciSpec => ciSpec.id === aci.id);
        return spec.root === this.rootImpact.id;
      })).map(a => {
        const customImpact = a.customImpacts.find(aci => this.customImpacts.find(ciSpec => ciSpec.id === aci.id));
        const spec = this.customImpacts.find(ciSpec => ciSpec.id === customImpact.id);
        const impact = spec.level === 1 ? spec : this.customImpacts.find(ciSpec => ciSpec.id === spec.parentId);
        const detailedImpact = spec.level === 2 ? spec : null;

        return {
          action: a,
          impactText: impact.text,
          detailedImpactText: detailedImpact && detailedImpact.text,
        }
      });


      const sorted = _.orderBy(actions, ['impactText', 'detailedImpactText']);
      return sorted.map(a => a.action);
    },
  },
}
</script>

<style scoped>

</style>
