import Vue from 'vue'
import Vuex from 'vuex'
import systemApi from "./api/system";

Vue.use(Vuex)

localStorage.getItem('planSelectedForDashboard')

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mainMenuOpen: false,
    systemDataLoaded: false,
    systemData: {},
    tenant: null,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {},
    },
    loadingOverlay: true,
    showTenantPaymentWarning: true,
    planSelectedForDashboard: null,
  },
  getters: {
    tenantNotActive: state => {
      return state.tenant != null && state.tenant.status === 'inactive';
    }
  },
  mutations: {
    hideTenantPaymentWarning(state) {
      state.showTenantPaymentWarning = false;
    },
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
      console.log(payload, 'setLoggedIn');
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setSystemData(state, payload) {
      state.systemData = payload;
      state.systemDataLoaded = true;
      state.loadingOverlay = false;
      console.log('systemData loaded');
    },
    setTenant(state, payload) {
      state.tenant = payload;
    },
    setPlanForDashboards(state, payload) {
      state.planSelectedForDashboard = payload;
    }
  },
  actions: {
    async loadSystemData(context) {
      const systemData = await systemApi.getFullSystemData();
      context.commit('setSystemData', systemData);
    },
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
  }
})
