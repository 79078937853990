import Vue from 'vue';

export default new Vue({
  data() {
    return {
      innerTenant: null,
    }
  },
  watch: {
    innerTenant(to) {
      localStorage.setItem("tenant", to);
    },
  },
  computed: {
    tenant() {
      return this.innerTenant;
    },
  },
  created() {
    this.setTenant(localStorage.getItem("tenant" ));
  },
  methods: {
    setTenant(tenant) {
      this.innerTenant = tenant;
    },
  },
})
