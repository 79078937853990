import * as math from 'mathjs';
import _ from 'lodash';
import dayjs from 'dayjs';

function calculateSDGIndexForValue(action, value) {
  if (!action.containsData) {
    throw new Error('cannot call this when no value exists, should be filtered out')
  }
  let startValue = action.startValue;
  const endValue = action.targetValue;
  const currentValue = value;


  if (startValue === endValue) {
    startValue = 0;
  }

  const result = math.evaluate('(currentValue - startValue) / (endValue - startValue)', { startValue, endValue, currentValue });
  if (isNaN(result)) {
    return 0;
  }

  if (result < 0) {
    return 0;
  }

  if (result > 1) {
    return 1;
  }

  return result;
}

function convertData(action, dataPoints) {
  let last = null;
  let lastMom = null;
  const values = [];


  let newPointsArray = [...dataPoints];
  newPointsArray.push({
    value: action.startValue,
    type: 'ABSOLUTE',
    date: action.startDate,
    source: action.source,
  });
  newPointsArray = _.sortBy(newPointsArray, ['date'])
  lastMom = null;

  let currentValue = 0;
  newPointsArray.forEach(dp => {
    if (dp.type === 'ABSOLUTE') {
      currentValue = dp.value;
    } else {
      currentValue = math.sum(currentValue, dp.value);
    }

    const mom = dayjs(dp.date);

    const d = {
      date: mom,
      dateMillis: mom.unix() * 1000,
      value: currentValue,
      indexValue: math.round(calculateSDGIndexForValue(action, currentValue) * 100),
      previousValue: last,
      previousIndexValue: last != null ? math.round(calculateSDGIndexForValue(action, last) * 100) : null,
      previousDate: lastMom != null ? lastMom : null,
      previousDateMillis: lastMom != null ? lastMom.unix() * 1000 : null,
      month: mom.month(),
      year: mom.year(),
    };

    values.push(d);

    lastMom = mom;
    last = currentValue;
  });

  return values;
}

export default {
  convertData,
  calculateSDGIndexForValue,
};
