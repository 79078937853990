<template>
  <v-row>
    <v-col cols="12">
      <hb-heading :editable="editable" v-model="title"
                  @input="titleChanged"
                  :subtitle="rootImpact.text"></hb-heading>
    </v-col>

    <v-col cols="12">
        <custom-impact-dashboard-tab :editable="true" :settings="settings" :dashboardData="dashboardData" :custom-impacts="customImpacts" :root-impact="rootImpact"></custom-impact-dashboard-tab>
    </v-col>
  </v-row>
</template>

<script>
  import CustomImpactDashboardTab from "@/components/CustomImpactDashboard/CustomImpactDashboardTab";

  export default {
    components: { CustomImpactDashboardTab },
    props: ['dashboardData', 'rootCustomImpactId', 'customImpacts', 'settings', 'editable'],
    name: 'CustomImpactDashboard',
    data() {
      return {
        title: '',
        showExportButtons: false,
        selectedRootImpact: null,
      }
    },
    watch: {
      settings() {
        this.setTitle();
      },
    },
    methods: {
      titleChanged() {
        this.$emit('title-changed', this.title);
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = this.$t('dashboards.my_custom_impacts');
        } else {
          this.title = this.settings.title;
        }
      },
    },
    computed: {
      rootImpact() {
        return this.customImpacts.find(ci => ci.id === Number.parseInt(this.rootCustomImpactId));
      },
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style scoped>

</style>
