<template>
  <div ref="customImpactTreemap">
  </div>
</template>

<script>
import _ from "lodash";
import Highcharts from "highcharts";

export default {
  props: ['rootImpact', 'customImpacts', 'dashboardData'],
  name: "CustomImpactTreemap",
  computed: {
    hasDetailedImpacts() {
      return this.customImpacts.find(ci => ci.root === this.rootImpact.id && ci.level === 2);
    },
    actionsGroupedByCustomImpact() {
      const actions = this.dashboardData.actions;

      const actionsGroupedByCustomImpact = {};
      actions.forEach(a => {
        const actionCustomImpactSpecs = a.customImpacts.map(aci => this.customImpacts.find(ciSpec => aci.id === ciSpec.id));
        const correctCISpec = actionCustomImpactSpecs.find(ci => ci.root === this.rootImpact.id);
        if (correctCISpec) {
          const parent = this.customImpacts.find(ciSpec => ciSpec.id === correctCISpec.parentId);
          if (!(parent.text in actionsGroupedByCustomImpact)) {
            actionsGroupedByCustomImpact[parent.text] = [];
          }
          actionsGroupedByCustomImpact[parent.text].push({ action: a, ciSpec: correctCISpec, parent });
        }
      })

      return actionsGroupedByCustomImpact;
    }
  },
  methods: {
    createChart() {
      const data = this.customImpacts.filter(ciSpec => ciSpec.parentId === this.rootImpact.id).map(e => {
        return {
          id: e.text,
          name: e.text,
          top: true,
          color: e.color,
        }
      });

      Object.keys(this.actionsGroupedByCustomImpact).forEach(customImpactText => {
        const objects = this.actionsGroupedByCustomImpact[customImpactText];
        const byCustomImpacts = _.groupBy(objects, 'ciSpec.text');

        Object.keys(byCustomImpacts).forEach(text => {
          const object = byCustomImpacts[text];
          data.push({
            name: text,
            longText: text,
            parent: object[0].parent.text,
            top: false,
            value: byCustomImpacts[text].length,
            actions: byCustomImpacts[text].map(o => o.action),
          });
        });
      });

      const chart = Highcharts.chart(this.$refs.customImpactTreemap, {
        chart: {
          style: {
            fontFamily: 'forma-djr-banner'
          },
          spacingBottom: 0,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: {
          text: '',
        },
        xAxis: {},
        tooltip: {
          // formatter: function(chart) {
          //   return that.tooltipFormatter(this, chart);
          // },
          // useHTML: true,
        },
        credits: false,
        series: [{
          type: "treemap",
          layoutAlgorithm: 'stripes',
          alternateStartingDirection: true,
          dataLabels: {
            style: {
              fontSize: '15px',
              textOutline: 0,
              fontFamily: 'forma-djr-banner',
              fontWeight: 'normal',
            },
          },
          levels: [{
            level: 1,
            layoutAlgorithm: 'sliceAndDice',
            dataLabels: {
              enabled: true,
              align: 'left',
              verticalAlign: 'top',
              style: {
                fontSize: '16px',
                textOutline: 0,
                fontWeight: 'bold',
                fontFamily: 'forma-djr-banner'
              },
            },
          }],
          data,
        }],
      });

      this.chart = chart;
    },
  },
  mounted() {
    if (this.dashboardData.actions.length > 0 && this.hasDetailedImpacts) {
      this.$nextTick(() => {
        this.createChart();
      });
    }
  }
}
</script>

<style scoped>

</style>
