<template>
  <div>
    <div id="longTermProgressChartContainer"></div>
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import DataService from '../../service/data.service';
  import Highcharts from "highcharts";
  import Vue from "vue";
  import ProgressChartTooltip from "@/components/Charts/ProgressChartTooltip";
  import params from "../../config/params";

  export default {
    props: ['actions', 'plan', 'settings', 'selectedYear'],
    name: 'LongTermProgressChart',
    data() {
      return {
        chart: null,
      }
    },
    watch: {
      selectedYear() {
        this.createChart();
      },
    },
    methods: {
      tooltipFormatter(data, chart) {
        const ComponentClass = Vue.extend(ProgressChartTooltip);
        const instance = new ComponentClass({ propsData: { pointData: data, chart } });
        const mountedInstance = instance.$mount();
        const html = mountedInstance.$el.innerHTML;
        return html;
      },
      createChart() {
        if (this.selectedYear == null) {
          return;
        }

        const maxDate = dayjs(this.targetDate);
        const maxDateMillis = maxDate.unix() * 1000;
        const minDate = dayjs(`${this.selectedYear}-01-01T00:00:00Z`);
        const minDateMillis = minDate.unix() * 1000;

        const converted = this.actions.map((action, i) => {
          const values = DataService.convertData(action, action.dataPoints);
          return {
            colorIndex: i,
            action,
            allValues: values,
            filteredValues: values.filter(v => minDate.isBefore(v.date))
          }
        }).filter(o => o.filteredValues.length > 1); // Drop all that only contain start point


        const series = [];
        const targetSeries = [];

        for (let i = 0; i < converted.length; i += 1) {
          const c = converted[i];
          const a = c.action;

          const data = c.allValues.map(v => {
            return {
              x: v.dateMillis,
              y: v.indexValue,
              action: a,
            };
          })

          series.push({
            name: a.description,
            //colorIndex,
            color: params.progressChartColors[c.colorIndex].color,
            legendIndex: i * 2,
            data,
            index: i * 2,
            id: i * 2,
          });

          const startMillis = dayjs(c.action.startDate).unix() * 1000;
          const targetMillis = dayjs(c.action.endDate).unix() * 1000;
          targetSeries.push({
            name: c.action.description + ' TARGET',
            dashStyle: 'dot',
            enableMouseTracking: false,
            data: [[startMillis, 0], [targetMillis, 100]],
            color: params.progressChartColors[c.colorIndex].color,
            index: i * 2 + 1,
            id: i * 2 + 1,
            showInLegend: false,
            marker: {
              enabled: false,
            }
          });
        }

        const that = this;
        this.chart = Highcharts.chart('longTermProgressChartContainer', {
          title: {
            text: '',
          },
          tooltip: {
            formatter: function(chart) {
              return that.tooltipFormatter(this, chart);
            },
            useHTML: true,
          },
          credits: false,
          yAxis: {
            title: '',
            max: 100,
            min: 0,
            labels: {
              style: {
                fontSize: '16px',
                color: 'black'
              },
            },
          },
          xAxis: {
            type: 'datetime',
            min: minDateMillis,
            max: maxDateMillis,
            labels: {
              style: {
                fontSize: '16px',
                color: 'black'
              },
            },
          },
          plotOptions: {
            series: {
              events: {
                hide(e) {
                  const index = e.target.index;
                  if (index % 2 === 0) {
                    const targetSerie = this.chart.get(index + 1);
                    targetSerie.hide();
                  }
                },
                show(e) {
                  const index = e.target.index;
                  if (index % 2 === 0) {
                    const targetSerie = this.chart.get(index + 1);
                    targetSerie.show();
                  }

                },
              }
            }
          },
          legend: {
            // https://api.highcharts.com/highcharts/legend.align
            itemStyle: {
              fontFamily: 'forma-djr-banner, Tahoma',
              fontWeight: 'normal',
            },
            align: 'left',
          },
          chart: {
            backgroundColor: 'transparent',
            height: '300px',
            style: {
              fontFamily: 'forma-djr-banner, Tahoma',
            },
          },
          series: [...series, ...targetSeries],
        });
      },
    },
    computed: {
      targetDate() {
        const endDates = this.actions.map(a => a.endDate);
        return _.max(endDates);
      },
    },
    mounted() {
      this.createChart();
    }
  }
</script>

<style scoped>

</style>
