<template>
  <v-card class="sdg-card rounded-0 blue-border">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-list color="transparent" class="py-0">
            <template v-if="$vuetify.breakpoint.lgAndUp"> <!-- Header for large screens -->
              <v-list-item dense>
                <v-list-item-avatar :style="{width: $vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xsOnly ? '40px' : '60px' : '80px'}" style="height: 0;">
                </v-list-item-avatar>
                <v-list-item-content class="align-self-start align-items-start py-2">
                  <v-list-item-subtitle class="caption text-wrap">
                    <v-row class="secondary--text">
                      <v-col cols="2">
                        {{ $tc('dashboards.action', 1) }}
                      </v-col>
                      <v-col cols="2">
                        {{ $t('dashboards.status')}}
                      </v-col>
                      <v-col cols="3">
                        {{ $t('dashboards.sdg_business_indicator') }}
                      </v-col>
                      <v-col cols="2">
                        {{ $t('dashboards.esg')}}
                      </v-col>
                      <v-col cols="3">
                        {{ $t('dashboards.detailed_esg')}}
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="sdgIndex in Object.keys(actionsGroupedBySdgs)" :key="sdgIndex" class="align-items-start">
              <v-list-item-avatar tile :size="$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xsOnly ? 40 : 60 : 80" class="align-self-start my-3">
                <v-img eager :src="`/img/sdgicons/${sdgIndex}.png`" @click="navigateToSDGDashboard(sdgIndex)" style="cursor: pointer"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="align-self-start align-items-start py-2">
                <v-list-item-subtitle v-for="action in actionsGroupedBySdgs[sdgIndex]" :key="action.id" class="align-self-start text-wrap">
                  <indicator-table-action-item :action="action"></indicator-table-action-item>
                  <v-divider v-if="$vuetify.breakpoint.mdAndDown"></v-divider>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import IndicatorTableActionItem from "@/components/ImpactDashboard/IndicatorTableActionItem";
export default {
  name: 'ImpactSDGIndicatorsTable',
  components: { IndicatorTableActionItem },
  props: ['actionsGroupedBySdgs'],
  methods: {
    navigateToSDGDashboard(sdgIndex) {
      if (this.$router != null) {
        const firstAction = this.actionsGroupedBySdgs[sdgIndex][0];
        this.$router.push({ name: 'sdg_dashboard', params: { sdgIndex, planId: firstAction.planId } });
      }
    }
  },
}
</script>

<style scoped>

</style>
