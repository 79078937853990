<template>
  <div id="esgImpactPieContainer"></div>
</template>

<script>
  import _ from "lodash";
  import Vue from 'vue';
  import Highcharts from "highcharts";

  import params from "../../config/params";
  import EsgImpactPieTooltip from "@/components/Charts/EsgImpactPieTooltip";

  export default {
    props: ['actions'],
    name: 'EsgImpactPie',
    watch: {
      actions() {
        this.createChart();
      },
    },
    data() {
      return {
        chart: null,
      }
    },
    methods: {
      tooltipFormatter(data, chart) {
        const ComponentClass = Vue.extend(EsgImpactPieTooltip);
        const instance = new ComponentClass({ propsData: { pieData: data, chart } });
        const mountedInstance = instance.$mount();
        const html = mountedInstance.$el.innerHTML;
        return html;
      },
      createChart() {
        const data = Object.keys(this.actionsGroupedByESG).map(esgName => {
          const actions = this.actionsGroupedByESG[esgName];
          const esg = params.esgs.find(e => e.text === esgName);
          return {
            id: esg.text,
            name: esg.text,
            color: esg.color,
            y: actions.length,
            actions,
          }
        })

        const that = this;
        const chart = Highcharts.chart('esgImpactPieContainer', {
          chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            style: {
              fontFamily: 'forma-djr-banner'
            },
          },
          legend: {
            itemStyle: {
              fontFamily: 'forma-djr-banner',
              fontWeight: 'normal',
            }
          },
          title: {
            text: '',
          },
          tooltip: {
            formatter: function(chart) {
              return that.tooltipFormatter(this, chart);
            },
            useHTML: true,
          },
          credits: false,
          series: [{
            name: '',
            dataLabels: {
              style: {
                fontWeight: 'normal',
              }
            },
            colorByPoint: true,
            data,
          }]
        });

        this.chart = chart;
      },
    },
    computed: {
      sdgCount() {
        const grouped = _.groupBy(this.actions, 'sdgIndex');
        return Object.keys(grouped).length;
      },
      actionsGroupedByImpacts() {
        return _.groupBy(this.actions, 'impactText');
      },
      actionsGroupedByESG() {
        return _.groupBy(this.actions, 'esg');
      },
    },
    mounted() {
      this.createChart();
    }
  }
</script>

<style scoped>

</style>
