<template>
  <div>
    <v-col cols="12">
      <hb-heading :editable="editable" v-model="title"
                  @input="titleChanged" :subtitle="$tc('dashboards.title', 1)"></hb-heading>
    </v-col>

    <v-row>
      <v-col cols="12" md="4" xl="3">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card class="sdg-card rounded-0 text-center white" id="index-score-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
              <ExportImageButton v-if="editable" :needsExtraSpaceOnTop="true" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#index-score-card'}" />
              <v-card-title class="sdg-card-title" style="display: block;">
                <div>
                  <div class="sdg-date-title">{{ $formatDateWithMonthName(latestDate) }}</div>
                  <div class="sdg-subtitle">{{ $t('dashboards.overall_sdg_index_score') }}</div>
                </div>
              </v-card-title>
              <v-card-text>
                <template v-if="overallScore != null">
                <div class="sdg-value-text sdg-green--text">{{ overallScore }}</div>
                </template>
                <template v-else>
                  <div class="sdg-value-text text-small sdg-green--text">{{$t('plan.action_no_data_yet')}}</div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-row justify="center" style="position: relative;" id="status-and-trend-explained" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false" no-gutters>
              <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#status-and-trend-explained'}" />
              <v-list dense color="transparent">
                <v-subheader>{{ $t('dashboards.status') }}</v-subheader>
                <v-list-item>
                  <v-list-item-avatar size="17">
                    <div class="status-bubble green lighten-1"></div>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.goal_achieved') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17">
                    <div class="status-bubble yellow lighten-1"></div>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.almost_there') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17">
                    <div class="status-bubble orange lighten-1"></div>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.still_challenges') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17">
                    <div class="status-bubble red lighten-1"></div>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.starting_your_journey') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-subheader>{{ $t('dashboards.trend') }}</v-subheader>
                <v-list-item>
                  <v-list-item-avatar size="17"><v-icon size="18" class="on_track" color="green lighten-1">lnr lnr-arrow-up</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.on_track') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17"><v-icon size="18" class="moderately_improving" color="#e3d026">lnr lnr-arrow-up</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.moderately_improving') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17"><v-icon size="18" class="stagnating" color="orange darken-1">lnr lnr-arrow-up</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.stagnating') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="17"><v-icon size="18" class="action_needed" color="red lighten-1">lnr lnr-arrow-up</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dashboards.action_needed') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" xl="9">
        <v-card class="sdg-card rounded-0 blue-border" id="status-and-trend-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
          <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#status-and-trend-card'}" />
          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-list color="transparent" class="py-0">
                  <template v-if="$vuetify.breakpoint.xlOnly"> <!-- Header for large screens -->
                    <v-list-item dense>
                      <v-list-item-avatar :style="{width: $vuetify.breakpoint.lgAndDown ? $vuetify.breakpoint.xsOnly ? '40px' : '60px' : '80px'}" style="height: 0;">
                      </v-list-item-avatar>
                      <v-list-item-content class="align-self-start align-items-start py-2">
                        <v-list-item-subtitle class="caption text-wrap">
                          <v-row class="secondary--text">
                            <v-col cols="2">
                              {{ $tc('dashboards.action', 1) }}
                            </v-col>
                            <v-col cols="3">
                              {{ $t('dashboards.sdg_business_indicator') }}
                            </v-col>
                            <v-col cols="1" class="text-center">
                              {{ $t('dashboards.trend') }}
                            </v-col>
                            <v-col cols="2" class="text-no-wrap">
                              {{ $t('dashboards.current_date') }}
                            </v-col>
                            <v-col cols="2" class="text-no-wrap">
                              {{ $t('dashboards.target_date') }}
                            </v-col>
                            <v-col cols="1" class="text-center">
                              {{ $t('dashboards.sdg_index') }}
                            </v-col>
                            <v-col cols="1" class="text-center">
                              {{ $t('dashboards.status') }}
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item v-for="sdgIndex in Object.keys(actionsGroupedBySdgs)" :key="sdgIndex" class="align-items-start" :to="toSDGLink(sdgIndex)">
                    <v-list-item-avatar tile :size="$vuetify.breakpoint.lgAndDown ? $vuetify.breakpoint.xsOnly ? 40 : 60 : 80" class="align-self-start my-3"
                    >
                      <v-img eager :src="`/img/sdgicons/${sdgIndex}.png`"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="align-self-start align-items-start py-2">
                      <v-list-item-subtitle v-for="action in actionsGroupedBySdgs[sdgIndex]" :key="action.id" class="align-self-start text-wrap">
                        <MySDGPerformanceActionItem :action="action"></MySDGPerformanceActionItem>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import * as math from "mathjs";
  import _ from "lodash";
  import MySDGPerformanceActionItem from "./ActionItem";
  import dayjs from 'dayjs';
  import ExportImageButton from "@/components/ExportImageButton";

  export default {
    components: {ExportImageButton, MySDGPerformanceActionItem },
    props: ['plan', 'settings', 'actions', 'editable'],
    name: 'MySDGPerformance',
    data() {
      return {
        title: '',
        showExportButtons: false,
      }
    },
    methods: {
      toSDGLink(sdgIndex) {
        if (this.useLinks) {
          return { name: 'sdg_dashboard', params: { sdgIndex, tenantId: this.$tenant, planId: this.plan.id }};
        }

        return null;
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = `${this.$t('dashboards.my_sdg_performance')}`;
        } else {
          this.title = this.settings.title;
        }
      },
      titleChanged() {
        this.$emit('title-changed', this.title);
      },
    },
    computed: {
      useLinks() {
        return this.$router != null;
      },
      overallScore() {
        const indices = this.actions.filter(a => a.containsData).map(a => this.$calculateSDGIndexPretty(a));
        if (indices.length === 0) {
          return null;
        }
        return math.round(math.mean(indices));
      },
      actionsGroupedBySdgs() {
        return  _.groupBy(this.actions, 'sdgIndex');
      },
      latestDate() {
        const currentValueDates = this.actions.flatMap(a => a.currentValue).filter(v => v).map(v => dayjs(v.date));

        // Sort so that the latest date is first
        currentValueDates.sort((a, b) => a.isBefore(b) ? 1 : -1);

        if (currentValueDates.length === 0)
          return null;

        return currentValueDates[0];
      },
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style lang="scss" scoped>
  .stagnating {
    transform-origin: center;
    transform: rotate(90deg);
  }

  .moderately_improving {
    transform-origin: center;
    transform: rotate(45deg);
  }

  .action_needed {
    transform-origin: center;
    transform: rotate(135deg);
  }

  .status-bubble {
    border-radius: 50%;
    display: block;
    width: 10px;
    height: 10px;
  }
</style>
