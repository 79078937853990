import axios from 'axios';

/*
 * Used for system data calls (only logged in user required, data that's not private but commonly used in the system
 * for example roles or some parameters
 */

const systemApi = {
  async getRoles() {
    const response = await axios.get(`/api/system/roles`);
    return response.data;
  },

  async getIndicatorSpecs() {
    const response = await axios.get('/api/system/indicator')
    const indicators = response.data;
    indicators.forEach(i => i.text = `${i.code}, ${i.shortDescription}`)

    return indicators;
  },

  async getFullSystemData() {
    const response = await axios.get('/api/system')
    const data = response.data;
    data.indicators.forEach(i => i.text = `${i.code}, ${i.shortDescription}`)
    data.indicators.sort((a, b) => {
      const aParts = a.indicatorIndex.split('.');
      const bParts = b.indicatorIndex.split('.');

      function containsAnyLetter(str) {
        return /[a-zA-Z]/.test(str);
      }
      function compare(str1, str2) {
        if (!containsAnyLetter(str1) && !containsAnyLetter(str2)) {
          return Number.parseInt(str1) - Number.parseInt(str2);
        }
        return str1 - str2;
      }

      const a1 = aParts[0];
      const b1 = bParts[0];
      if (a1 !== b1) {
        return compare(a1, b1);
      }


      const a2 = aParts[1];
      const b2 = bParts[1];
      if (a2 !== b2) {
        return compare(a2, b2);
      }

      const a3 = aParts[2];
      const b3 = bParts[2];
      return compare(a3, b3);
    });
    return data;
  },

  /**
   *
   * @param code
   * @param {object} data existing code
   * @param {string} data.code
   * @param {string} data.shortDescription
   * @param {string} data.description
   * @param {string} data.keywords
   * @param {string} data.impactId
   * @returns {Promise<void>}
   */
  async updateIndicator(code, data) {
    const response = await axios.post(`/api/system/indicator/${code}`, data);
    return response.data;
  },
  async updateUnit(id, data) {
    const response = await axios.post(`/api/system/unit/${id}`, data);
    return response.data;
  },
  async createUnit(data) {
    const response = await axios.post(`/api/system/unit`, data);
    return response.data;
  },
  async deleteUnit(id) {
    const response = await axios.delete(`/api/system/unit/${id}`);
    return response.data;
  }
};

export default systemApi;
