<template>
  <div>
    <div id="chartContainer">
    </div>
  </div>
</template>

<script>
  import * as math from 'mathjs';
  import Vue from 'vue';

  import Highcharts from 'highcharts';
  import SDGPieTooltip from "./SDGPieTooltip";
  import VariablePie from 'highcharts/modules/variable-pie';
  VariablePie(Highcharts);
  import _ from "lodash";

  export default {
    props: {
      actions: {
        type: [Array],
        default: () => [],
      },
    },
    data() {
      return {
        chart: {},
      }
    },
    watch: {
      actions(newValue) {
        if (newValue.length > 0) {
          this.createChart();
        }
      },
    },
    computed: {
      currentSDGActions() {
        return this.actions.filter(a => a.sdgIndex === Number.parseInt(this.sdgIndex));
      },
      sdgCount() {
        const grouped = _.groupBy(this.actions, 'sdgIndex');
        return Object.keys(grouped).length;
      },
      actionsGroupedBySDGs() {
        return _.groupBy(this.actions, 'sdgIndex');
      },
    },
    methods: {
      tooltipFormatter(data, chart) {
        const ComponentClass = Vue.extend(SDGPieTooltip)
        const instance = new ComponentClass({ propsData: { pieData: data, chart } })
        const mountedInstance = instance.$mount();
        const html = mountedInstance.$el.innerHTML;
        return html;
      },
      createChart() {
        const actionsGroupedBySDGs = this.actionsGroupedBySDGs;
        const data = Object.keys(actionsGroupedBySDGs).map(sdgIndex => {
          const actions = actionsGroupedBySDGs[sdgIndex];

          const indices = actions.map(a => this.$calculateSDGIndexPretty(a));
          const avgIndex = math.mean(indices);

          return {
            name: 'SDG ' + sdgIndex,
            sdgIndex,
            y: actions.length,
            z: math.round(avgIndex),
            color: this.$sdg(sdgIndex).color,
            actions,
          }
        })

        const that = this;
        this.chart = Highcharts.chart('chartContainer', {
          credits: false,
          chart: {
            type: 'variablepie',
            backgroundColor: 'transparent',
            style: {
              fontFamily: 'forma-djr-banner'
            },
          },
          title: {
            text: ''
          },
          tooltip: {
            formatter: function(chart) {
              return that.tooltipFormatter(this, chart);
            },
            useHTML: true,
          },
          series: [{
            minPointSize: 100,
            innerSize: '0%',
            tooltip: {
              borderColor: 'black',
            },
            dataLabels: {
              style: {
                fontSize: '15px',
                textOutline: 0,
                fontFamily: 'forma-djr-banner',
                fontWeight: 'normal',
              },
            },
            data,
            point:{
              events: {
                click(e) {
                  that.$emit('sdg-clicked', e.point.sdgIndex);
                },
              },
            },
          }]
        });
      },
    },
    mounted() {
      if (this.actions.length > 0) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  }
</script>

<style scoped>

</style>
