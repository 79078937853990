import axios from 'axios';
import CustomImpactService from "@/service/custom_impact.service";

/*
 * Used for tenant API calls.
 * for example: get tenants users, get tenants data
 *
 * X-TENANT-ID header required for ALL calls
 */

const tenantApi = {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getCurrentTenant() {
    const response = await axios.get('/api/tenant');
    return response.data;
  },
  /**
   *
   * @param {!object} data
   * @param {!string} data.name
   * @param {string} data.streetAddress
   * @param {string} data.city
   * @param {string} data.country
   * @param {string} data.zipCode
   * @param {int} data.turnover
   * @param {int} data.business
   * @param {int} data.headcount
   * @returns {Promise<any>}
   */
  async updateCurrentTenant(data) {
    const response = await axios.post('/api/tenant', data);
    return response.data;
  },
  /**
   *
   * @param {!Object} data
   * @param {!string} data.firstName
   * @param {!string} data.lastName
   * @param {!string} data.email
   * @returns {Promise<any>}
   */
  async addNewUserToTenant(data) {
    const response = await axios.post('/api/tenant/user', {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
    return response.data;
  },
  async removeUserFromTenant(userId) {
    const response = await axios.delete(`/api/tenant/user/${userId}`);
    return response.data;
  },

  //
  // CONTRIBUTORS
  //
  async getContributors() {
    const response = await axios.get('/api/tenant/contributor');
    return response.data;
  },
  async createContributor(data) {
    const response = await axios.post('/api/tenant/contributor/', data);
    return response.data;
  },
  async updateContributor(id, data) {
    const response = await axios.post(`/api/tenant/contributor/${id}`, data);
    return response.data;
  },
  async getContributorGroups() {
    const response = await axios.get('/api/tenant/contributor/group');
    return response.data;
  },
  async deleteContributorGroup(contributorGroupId) {
    const response = await axios.delete(`/api/tenant/contributor/group/${contributorGroupId}`);
    return response.data;
  },
  async createContributorGroup(data) {
    const response = await axios.post('/api/tenant/contributor/group', data);
    return response.data;
  },
  async updateContributorGroup(contributorGroupId, data) {
    const response = await axios.post(`/api/tenant/contributor/group/${contributorGroupId}`, data);
    return response.data;
  },
  async addContributorToGroup(contributorId, groupId) {
    const response = await axios.post(`/api/tenant/contributor/${contributorId}/group/${groupId}`);
    return response.data;
  },
  async removeContributorFromGroup(contributorId, groupId) {
    const response = await axios.delete(`/api/tenant/contributor/${contributorId}/group/${groupId}`);
    return response.data;
  },

  //
  // PLANS
  //
  async getPlans() {
    const response = await axios.get('/api/tenant/plan');
    return response.data;
  },
  async getPlansForTenant(tenantId) {
    const response = await axios.get('/api/tenant/plan', {
      headers: {
        'X-tenant-id': tenantId,
      }
    });
    return response.data;
  },

  async getPlan(planId) {
    const response = await axios.get(`/api/tenant/plan/${planId}`);
    return response.data;
  },
  /**
   *
   * @param {!object} data
   * @param {!string} data.name name of the plan
   * @returns {Promise<any>}
   */
  async createPlan(data) {
    const response = await axios.post('/api/tenant/plan', data);
    return response.data;
  },
  /**
   *
   * @param planId
   * @param {!object} data
   * @param {!string} data.name name of the plan
   * @returns {Promise<any>}
   */
  async updatePlan(planId, data) {
    const response = await axios.post(`/api/tenant/plan/${planId}`, data);
    return response.data;
  },
  async deletePlan(planId) {
    const response = await axios.delete(`/api/tenant/plan/${planId}`);
    return response.data;
  },
  /**
   * Create new action for plan.
   * @param planId
   * @param data
   * @returns {Promise<any>}
   */
  async createAction(planId, data) {
    const response = await axios.post(`/api/tenant/plan/${planId}/action`, data);
    return response.data;
  },
  /**
   * Update existing action.
   * @param actionId
   * @param data
   * @returns {Promise<any>}
   */
  async updateAction(actionId, data) {
    const response = await axios.post(`/api/tenant/action/${actionId}`, data);
    return response.data;
  },
  async deleteAction(actionId) {
    const response = await axios.delete(`/api/tenant/action/${actionId}`);
    return response.data;
  },
  async getAction(actionId) {
    const response = await axios.get(`/api/tenant/action/${actionId}`);
    return response.data;
  },
  async getDataPoints(actionId) {
    const response = await axios.get(`/api/tenant/action/${actionId}/datapoint`);
    const dataPoints = response.data;
    dataPoints.forEach(dp => dp.deleted = false);
    return dataPoints;
  },
  async createDataPoint(actionId, data) {
    const response = await axios.post(`/api/tenant/action/${actionId}/datapoint`, data);
    return response.data;
  },
  async updateDataPoint(dataPointId, data) {
    const response = await axios.post(`/api/tenant/datapoint/${dataPointId}`, data);
    return response.data;
  },
  async deleteDataPoint(dataPointId) {
    const response = await axios.delete(`/api/tenant/datapoint/${dataPointId}`);
    return response.data;
  },
  async addActionToGroup(actionId, groupId) {
    const response = await axios.post(`/api/tenant/action/${actionId}/group/${groupId}`);
    return response.data;
  },
  async removeActionFromGroup(actionId, groupId) {
    const response = await axios.delete(`/api/tenant/action/${actionId}/group/${groupId}`);
    return response.data;
  },


  async getHellos() {
    const response = await axios.get('/api/tenant/hello')
    return response.data;
  },
  async addHello(msg) {
    const response = await axios.post('/api/tenant/hello', { msg });
    return response.data;
  },
  async deleteHello(helloId) {
    const response = await axios.delete(`/api/tenant/hello/${helloId}`);
    return response.data;
  },
  async loadTenantUsers() {
    const response = await axios.get('/api/tenant/user');
    return response.data;
  },


  async uploadLogo(file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);

    // const config =  {
    //   onUploadProgress: progressEvent => {
    //     this.imageUploadProgress = progressEvent.loaded / progressEvent.total * 100;
    //   },
    // };
    const response = await axios.post(`/api/tenant/image/0/logo`, formData/*, config*/);
    return response.data;
  },
  async getLogo() {
    const response = await axios.get('/api/tenant/image/0/logo')
    const images = response.data;
    if (images.length > 0) {
      return images[0];
    } else {
      return null;
    }
  },
  async deleteLogo(logo) {
    await axios.delete(`/api/tenant/image/${logo}`)
  },
  /**
   *
   * @param type sdg,overview,impact,my_sdg_performance,action
   * @param {Number} planId
   * @param {Number} [param2] sdgIndex if type sdg, actionId if type action
   * @returns {Promise<any>}
   */
  async getDashboardData(type, planId, param2) {
    let code = `${type}-${planId}`;
    if (param2 != null) {
      code += `-${param2}`;
    }
    const response = await axios.get(`/api/tenant/dashboard/${code}/data`);
    return response.data;
  },
  async getDashboardSettings(type, planId, param2) {
    let code = `${type}-${planId}`;
    if (param2 != null) {
      code += `-${param2}`;
    }
    const response = await axios.get(`/api/tenant/dashboard/${code}`);
    return response.data;
  },
  /**
   * possible to set dashboard as shared
   *
   * @param code code of dashboard ( my_sdg_performance-1, sdg-2-1 )
   * @param {Object} data
   * @param {boolean} data.shared
   * @param {Object} data.meta
   * @param {String} data.title
   * @returns {Promise<void>}
   */
  async updateDashboardSettings(code, data) {
    const response = await axios.post(`/api/tenant/dashboard/${code}`, data);
    return response.data;
  },
  async copyActionToPlan(id, selectedPlanId) {
    const response = await axios.post(`/api/tenant/action/${id}/copy`, { planId: selectedPlanId });
    return response.data;
  },
  async copyActionToAccount(id, data) {
    const response = await axios.post(`/api/tenant/action/${id}/accountcopy`, data);
    return response.data;
  },
  async reorderPlans(planIds) {
    const response = await axios.post(`/api/tenant/plan/orders`, { planIds });
    return response.data;
  },
  async reorderPlanActions(planId, actionIds) {
    const response = await axios.post(`/api/tenant/plan/${planId}/action_orders`, { actionIds });
    return response.data;
  },
  async startTrial() {
    const response = await axios.post(`/api/tenant/trial`);
    return response.data;
  },
  async takeScreenshot(options) {
    const response = await axios.post(`/api/tenant/print`, { element: options.elementId, dashboardCode: options.dashboardCode }, { responseType: 'blob'});
    return URL.createObjectURL(response.data)
  },
  async getSamplePlan() {
    const response = await axios.post('/api/tenant/plan/copy_sample');
    return response.data;
  },
  async getCustomImpacts() {
    const response = await axios.get('/api/tenant/custom_impact');
    const data = response.data;
    const impacts = CustomImpactService.fillLevelAndRoot(data);

    return impacts;
  },
  async createCustomImpact(customImpact) {
    const response = await axios.post('/api/tenant/custom_impact', customImpact);
    return response.data;
  },
  async updateCustomImpact(customImpactId, customImpact) {
    const response = await axios.post(`/api/tenant/custom_impact/${customImpactId}`, customImpact);
    return response.data;
  },
  async deleteCustomImpact(customImpactId) {
    const response = await axios.delete(`/api/tenant/custom_impact/${customImpactId}`)
    return response.data;
  }
};

export default tenantApi;
