<template>
  <v-col  v-bind="$attrs">
    <v-card class="sdg-card blue-border rounded-0 fill-height" :class="$vuetify.breakpoint.mdAndDown ? '' : 'pa-10 pt-0'" :id="id" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
      <ExportImageButton v-if="editable" :show="showExportButtons" :options="{ dashboardCode, elementId: '#' + id}" />
      <v-card-text>
        <div class="sdg-heading-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-10' : ''">{{ title }}</div>
        <div v-if="subtitle" class="sdg-subtitle mb-5">{{ subtitle }}</div>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import ExportImageButton from "@/components/ExportImageButton";
export default {
  components: { ExportImageButton },
  props: ['id', 'dashboardCode', 'title', 'editable', 'subtitle'],
  data() {
    return {
      showExportButtons: false
    }
  },

}
</script>

<style scoped>

</style>
