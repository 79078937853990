<template>
  <v-row :no-gutters="$vuetify.breakpoint.lgAndDown" :class="$vuetify.breakpoint.lgAndDown ? 'mb-3' : ''">
    <!-- Large screens -->
    <template v-if="$vuetify.breakpoint.xlOnly">
      <v-col cols="2" class="font-weight-medium">
        {{ action.description }}
      </v-col>
      <v-col cols="3">
        {{ action.indicatorDescription }}
      </v-col>
      <v-col cols="1" class="text-center">
        <v-icon :class="trend" :color="trendColor">lnr lnr-arrow-up</v-icon>
      </v-col>
      <v-col cols="2" class="text-no-wrap">
        {{ currentDate ? currentDate : $t('plan.action_no_data_yet') }}
      </v-col>
      <v-col cols="2" class="text-no-wrap">
        {{ targetDate ? targetDate : $t('plan.action_no_data_yet')}}
      </v-col>
      <v-col cols="1" class="text-center">
        {{ action.containsData ? index : $t('plan.action_no_data_yet') }}
      </v-col>
      <v-col cols="1" class="text-center">
        <v-avatar size="10" :color="status"></v-avatar>
      </v-col>
    </template>

    <!-- Small screens -->
    <template v-if="$vuetify.breakpoint.lgAndDown">
      <v-col class="font-weight-medium pa-1">
        {{ action.description }}
      </v-col>
      <v-col class="text-right pa-1">
        <v-row justify="end" align="center" no-gutters>
          <div class="font-weight-medium caption px-1" style="line-height: unset;"><template v-if="$vuetify.breakpoint.smAndUp">SDG Index: </template>{{ action.containsData ? index : $t('plan.action_no_data_yet') }}</div>
          <div class="px-1" style="margin-top: -6px;">
            <v-icon size="18" :class="trend" :color="trendColor">lnr lnr-arrow-up</v-icon>
          </div>
          <div class="px-1" style="margin-top: -6px;">
            <v-avatar size="10" :color="status"></v-avatar>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-1">
        {{ action.indicatorDescription }}
      </v-col>
      <v-col cols="6" sm="12" class="caption pa-1 grey--text" style="line-height: 1rem;">
        <span class="mr-2">Current date:<br v-if="$vuetify.breakpoint.xsOnly" /> {{ currentDate ? currentDate : $t('plan.action_no_data_yet') }}</span>
        <template v-if="$vuetify.breakpoint.smAndUp">
          Target date: {{ targetDate ? targetDate : $t('plan.action_no_data_yet') }}
        </template>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="caption pa-1 grey--text" style="line-height: 1rem;">Target date:<br v-if="$vuetify.breakpoint.xsOnly" /> {{ targetDate ? targetDate : $t('plan.action_no_data_yet') }}</v-col>
    </template>
  </v-row>
</template>

<script>
  import dayjs from 'dayjs';
  const duration = require('dayjs/plugin/duration')
  dayjs.extend(duration)

  import * as math from "mathjs";

  export default {
    props: ['action'],
    name: 'MySDGPerformanceActionItem',
    computed: {
      index() {
        if (!this.action.containsData) {
          return null;
        }
        return this.$calculateSDGIndexPretty(this.action);
      },
      currentDate() {
        if (!this.action.currentValue) return false;
        return dayjs(this.action.currentValue.date).format('MMM-YYYY').toUpperCase();
      },
      targetDate() {
        if (!this.action.endDate) return false;
        return dayjs(this.action.endDate).format('MMM-YYYY').toUpperCase();
      },
      trend() {
        if (!this.action.containsData) return 'action_needed';

        if (this.goalGrowthRate === 0) { // Should maintain
          if (this.index >= 99) {
            return 'on_track';
          } else {
            return 'stagnating';
          }
        }

        const ratio = this.currentGrowthRate / this.goalGrowthRate;
        if (ratio >= 1) {
          return 'on_track'
        } else if (ratio >= 0.5) {
          return 'moderately_improving'
        } else if (ratio >= 0) {
          return 'stagnating';
        }

        return 'action_needed';
      },
      currentGrowthTimeSpan() {
        const action = this.action;
        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const momentDuration = dayjs.duration(duration);
        if (momentDuration.asMonths() < 1)
          return 'day'
        if (momentDuration.asMonths() <= 24)
          return 'month';

        return 'year'
      },
      currentGrowthTimeSpanText() {
        return this.currentGrowthTimeSpan;
      },
      goalGrowthRate() {
        const action = this.action;
        if (action.currentValue == null) {
          return 0;
        }

        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const timeUnits = dayjs.duration(duration).as(this.currentGrowthTimeSpan);

        const value = math.evaluate('(endValue - startValue) / timeUnits', { endValue: action.targetValue, startValue: action.startValue, timeUnits });
        return math.round(value, 1);
      },
      currentGrowthRateRaw() {
        const action = this.action;

        const startMoment = dayjs(action.startDate);
        const endMoment = dayjs(action.endDate);
        const duration = endMoment.diff(startMoment);

        const totalTimeUnits = dayjs.duration(duration).as(this.currentGrowthTimeSpan);

        let lastDataMoment = dayjs(this.action.currentValue.date);
        if (endMoment.isBefore(dayjs()) && lastDataMoment.isBefore(endMoment)) {
          lastDataMoment = endMoment.startOf('d');
        }

        if (endMoment.isAfter(dayjs()) && lastDataMoment.isBefore(dayjs())) {
          lastDataMoment = dayjs().startOf('d');
        }

        const timeUnits = dayjs.duration(lastDataMoment.diff(startMoment)).as(this.currentGrowthTimeSpan);
        const currentTimeUnits = math.max(timeUnits, 1);

        const scope =
          { currentValue: action.currentValue.value, totalTimeUnits, currentTimeUnits, startValue: action.startValue };
        const value = math.evaluate('(currentValue - startValue) / currentTimeUnits', scope);

        if (isNaN(value)) {
          return 0;
        }

        return value;
      },
      currentGrowthRate() {
        return math.round(this.currentGrowthRateRaw, 1);
      },
      status() {
        let color = "";
        if (this.index < 25) {
          color = 'red lighten-1';
        } else if (this.index < 50) {
          color = 'orange lighten-1';
        } else if (this.index < 75) {
          color = 'yellow darken-2';
        } else {
          color = 'green lighten-1';
        }
        return color;
      },
      trendColor() {
        let color = '';
        if (this.trend === 'stagnating') {
          color = 'orange darken-1';
        } else if (this.trend === 'moderately_improving') {
          color = '#e3d026';
        } else if (this.trend === 'action_needed') {
          color = 'red lighten-1';
        } else if (this.trend === 'on_track') {
          color = 'green lighten-1';
        }
        return color;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .stagnating {
    transform-origin: center;
    transform: rotate(90deg);
  }

  .moderately_improving {
    transform-origin: center;
    transform: rotate(45deg);
  }

  .action_needed {
    transform-origin: center;
    transform: rotate(135deg);
  }
</style>
