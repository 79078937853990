<template>
  <div>
    <strong>{{ pieData.key }}</strong>
    <div v-if="avg">Overall index {{ avg }}</div>
    <table style="padding-top: 5px" v-if="actionsByIndicatorCode.length > 0">
      <thead>
      <th>
        Indicator
      </th>
      <th class="pl-2">
        Actions
      </th>
      <th class="pl-5">
        Index
      </th>
      </thead>
      <tbody>
      <tr v-for="action in actionsByIndicatorCode" :key="action.code">
        <td>
          {{ action.code }}
        </td>
        <td class="text-center pl-2">
          {{ action.count }}
        </td>
        <td class="text-center pl-5" v-if="action.index != null">
          {{ action.index }}
        </td>
        <td class="text-center pl-5" v-else>
          No data yet
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else>
      No actions for this SDG.
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as math from 'mathjs';

export default {
  props: ['chart', 'pieData'],
  mounted() {

  },
  computed: {
    avg() {
      if (!this.pieData.point.actions) {
        return null;
      }
      return this.pieData.point.avg ? this.pieData.point.avg : 'No data yet';
    },
    point() {
      return this.pieData.point;
    },
    actionsByIndicatorCode() {
      const grouped = _.groupBy(this.point.actions, 'indicatorCode');

      return Object.keys(grouped).map(key => {
        const actionForKey = grouped[key];
        const indices = actionForKey.filter(a => a.containsData).map(a => this.$calculateSDGIndexPretty(a));

        const count = indices.length;

        return {
          code: key,
          index: count > 0 ? math.round(math.mean(indices)) : null,
          count: actionForKey.length,
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
