export default {
  fillLevelAndRoot(data) {
    const impacts = data.map(i => ({ ...i, children: [] }));

    const getRootId = (impact) => {
      if (impact.parentId) {
        const parent = impacts.find(i => i.id === impact.parentId);
        return getRootId(parent);
      }
      return impact.id;
    }

    const getLevel = (impact) => {
      if (impact.parentId) {
        const parent = impacts.find(i => i.id === impact.parentId);
        return getLevel(parent) + 1;
      } else {
        return 0;
      }
    }

    impacts.forEach(i => {
      i.level = getLevel(i);
      i.root = getRootId(i);
      if (i.parentId) {
        const parent = impacts.find(ii => ii.id === i.parentId);
        parent.children.push(i);
      }
    });

    return impacts;
  }
}
