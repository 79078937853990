<template>
  <div>
    <div ref="chartContainer">
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";

export default {
  props: ['rootImpact', 'customImpacts', 'dashboardData'],
  name: 'CustomImpactPiechart',
  data() {
    return {
    }
  },
  computed: {
    firstLevelImpacts() {
      return this.customImpacts.filter(ci => ci.parentId === this.rootImpact.id);
    }
  },
  methods: {
    createChart() {
      const firstLevelImpacts = this.firstLevelImpacts;
      const counts = {};
      firstLevelImpacts.forEach(fli => counts[fli.id] = 0);

      this.dashboardData.actions.forEach(a => {
        const actionCustomImpacts = a.customImpacts;
        actionCustomImpacts.forEach(aCi => {
          const ciSpec = this.customImpacts.find(ci => ci.id === aCi.id && ci.root === this.rootImpact.id);

          if (!ciSpec) {
            return;
          }

          let id = null;
          if (ciSpec.level === 2) {
            id = ciSpec.parentId;
          } else if (ciSpec.level === 1) {
            id = ciSpec.id;
          }
          if (id) {
            counts[id] = counts[id] + 1;
          }
        })
      });

      const data = Object.keys(counts).map(ciId => {

        const impact = firstLevelImpacts.find(fi => fi.id === Number.parseInt(ciId));
        return {
          name: impact.text,
          y: counts[ciId],
          color: impact.color
        }
      })

      this.chart = Highcharts.chart(this.$refs.chartContainer, {
        credits: false,
        chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'forma-djr-banner'
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        title: {
          text: ''
        },
        // tooltip: {
        //   formatter: function(chart) {
        //     return that.tooltipFormatter(this, chart);
        //   },
        //   useHTML: true,
        // },
        series: [{
          name: this.$t('dashboards.custom_impacts.amount_of_actions'),
          minPointSize: 100,
          innerSize: '0%',
          tooltip: {
            borderColor: 'black',
          },
          dataLabels: {
            style: {
              fontSize: '15px',
              textOutline: 0,
              fontFamily: 'forma-djr-banner',
              fontWeight: 'normal',
            },
          },
          data,
          point:{
            // events: {
            //   click(e) {
            //     that.$emit('sdg-clicked', e.point.sdgIndex);
            //   },
            // },
          },
        }]
      });
    },
  },
  mounted() {
    if (this.dashboardData.actions.length > 0) {
      this.$nextTick(() => {
        this.createChart();
      });
    }
  }
}
</script>

<style scoped>

</style>
