<template>
  <v-dialog v-model="dialog" max-width="500" @keydown.esc="cancel()">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
        <div>{{ $t('files.add_file') }}</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>lnr lnr-cross</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <div v-if="isImage" class="d-flex justify-center" style="width: 100%">
          <v-img :src="file.url" aspect-ratio="1" class="primary lighten-2 mb-5" style="border-radius: 10px; height: 300px"></v-img>
        </div>
        <v-text-field
          v-model="file.description"
          :disabled="saving"
          :label="$t('files.attachment_description')"
          autocomplete="off"
          hide-details
          item-value="">
        </v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
          :large="$vuetify.breakpoint.xsOnly"
          rounded
          tabindex="6"
          text
          @click.native="cancel()">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn>
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
          :large="$vuetify.breakpoint.xsOnly"
          :loading="saving"
          color="primary"
          rounded
          @click="saveFile">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-content-save</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddFileDialog',
  components: {},
  props: {},
  data() {
    return {
      dialog: false,
      saving: false,
      file: {
        description: '',
        url: '',
        filename: '',
        contentType: '',
      },
      fileAddedEvent: null,
    };
  },
  computed: {
    isImage() {
      return this.file.filename.indexOf('jpg' || 'png' || 'jpeg' || 'gif') !== -1;
    },
  },
  methods: {
    async open(fileAddedEvent) {
      this.fileAddedEvent = fileAddedEvent;
      const file = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop(),
      };
      this.file.description = file.filename;
      this.file.filename = file.filename;
      const reader = new FileReader();
      const ind = (this.previewIndex += 1);
      reader.onload = e => {
        const ig = {
          data: e.target.result,
          index: ind,
          loading: false,
        };
        this.file.url = ig.data;
      };
      reader.readAsDataURL(file.file);
      this.dialog = true;
    },
    saveFile() {
      const returnedTarget = Object.assign(this.fileAddedEvent, this.file);
      this.$emit('description-given', returnedTarget);
      this.file.description = '';
      this.dialog = false;
    },
    cancel() {
      this.file.description = '';
      this.dialog = false;
    },
  },
};
</script>
