/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import md5 from 'md5';
import * as math from 'mathjs';

import adminApi from "../api/admin";
import userApi from "../api/user";
import systemApi from "../api/system";
import tenantApi from "../api/tenant";
import tenantService from '../service/tenant.service';
import dataService from '../service/data.service'
import store from "../store";
import i18n from "../i18n";
import params from "../config/params";
import countries from "../config/countries";
import dayjs from 'dayjs';


const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $tenant() {
          const savedTenant = tenantService.tenant;
          if (savedTenant != null && this.$userInfo.tenants.find(t => t.id === savedTenant)) {
            return savedTenant;
          }

          if (savedTenant == null && this.$userInfo != null && this.$userInfo.tenants.length > 0) {
            return this.$userInfo.tenants[0];
          }

          return null;
        },
        $fullTenant() {
          const tenantId = this.$tenant;
          if (tenantId == null) {
            return null;
          }

          return this.$userInfo.tenants.find(t => t.id === tenantId);
        },
        $userApi() {
          return userApi;
        },
        $adminApi() {
          return adminApi;
        },
        $systemApi() {
          return systemApi;
        },
        $tenantApi() {
          return tenantApi;
        },
        $baseUrl() {
          const hasCustomBaseUrl = !!process.env.VUE_APP_API_BASEURL;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        }
      },
      methods: {
        $downloadAsImage(data, filename = 'sdg_export.png') {
          const link = document.createElement('a');
          const uri = data;
          if (typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;

            //Firefox requires the link to be in the body
            document.body.appendChild(link);

            //simulate click
            link.click();

            //remove the link when done
            document.body.removeChild(link);
          } else {
            window.open(uri);
          }
        },
        $getSharedUrl(tenant, uuid) {
          const baseUrl = window.location.href.split("/#")[0];
          const url = `${baseUrl}/s?t=${tenant}&d=${uuid}`;
          return url;
        },
        $getSharedContributorUrl(tenant, uuid) {
          const baseUrl = window.location.href.split("/#")[0];
          const url = `${baseUrl}/c?t=${tenant}&c=${uuid}`;
          return url;
        },
        $calculateSDGIndexPretty(action) {
          const index = this.$calculateSDGIndex(action) * 100;
          return index != null ? math.round(index) : null;
        },
        $calculateSDGIndex(action) {
          if (!action.containsData) {
            return null;
          }

          return this.$calculateSDGIndexForValue(action, action.currentValue.value);
        },
        $calculateSDGIndexForValue(action, value) {
          if (!action.containsData) {
            return null;
          }

          return dataService.calculateSDGIndexForValue(action, value);
        },
        $printPage() {
          window.print();
        },
        $getCountryNameFromCode(code) {
          const c = countries.find(c => c.code === code);
          if (c) {
            return c.name;
          }

          return '';
        },
        $getIndexForAction(action) {
          const startValue = action.startValue;
          const endValue = action.targetValue;

          const currentValue = action.currentValue.value;

          if (startValue == null || endValue == null || currentValue == null) {
            return 0;
          }

          return math.evaluate('(currentValue-startValue) / (endValue - startValue) * 100', { currentValue, startValue, endValue })
        },
        $getSDGImage(sdgIndex) {
          return `/img/sdgicons/${sdgIndex}.png`;
        },
        $sdg(sdgIndex) {
          return params.sdgs[sdgIndex];
        },
        $showSuccessNotification(message) {
          store.dispatch('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          store.dispatch('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          store.dispatch('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.log(err);
          if (err.response != null) {
            const data = err.response.data;
            if ('error_key' in data) {
              this.$showErrorNotification(i18n.t(data.error_key));
              return;
            }

            if (data.message === 'TENANT_NOT_ACTIVE') {
              this.$showErrorNotification(i18n.t('tenant_not_active'));
              return;
            }
          }

          this.$showErrorNotification(msg);
        },
        $reloadApp() {
          location.reload();
        },
        $formatTimeStamp(date) {
          return dayjs(date).format('LLLL');
        },
        $formatDateNoTime(date) {
          if (date) {
            return dayjs(date).format('DD.MM.YYYY');
          }
        },
        $formatTimeNoDate(date) {
          if (date) {
            return dayjs(date).format('HH:mm');
          }
        },
        $detailedEsgText(esg) {
          if (esg === 'Governance')
            return 'Help business to grow in a just and transparent way.';
          if (esg === 'Environment')
            return 'Help business to grow without harming the environment.';

          return 'Help business to grow while enhancing the social well-being.';
        },
        $formatDateWithMonthName(date) {
          return dayjs(date).format('MMMM D, YYYY');
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 60) {
          let hash = 0;
          if (string.length > 0) {
            for (var i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5((email.toLowerCase()).trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
        $formatDateTime(date) {
          return date ? dayjs(date).format('DD.MM.YYYY HH:mm:ss') : ''
        },
      }
    });
  },
};

export default HelperPlugin;
