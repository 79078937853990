<template>
  <v-app-bar color="white" light fixed app elevation="7" :height="$vuetify.breakpoint.smAndUp ? 110 : ''"
             class="ignore-export">

    <v-toolbar-title style="margin-left: 0; width: 100%;">
      <template v-if="!contributor">
        <router-link :to="planRoute">
          <img style="width: auto;"
               :style="{height: $vuetify.breakpoint.xsOnly ? '52px' : '105px', marginTop: $vuetify.breakpoint.xsOnly ? '10px' : '8px'}"
               src="/img/sdgmonitor-logo.svg"/>
        </router-link>
      </template>
      <template v-else>
        <img style="width: auto;"
             :style="{height: $vuetify.breakpoint.xsOnly ? '52px' : '105px', marginTop: $vuetify.breakpoint.xsOnly ? '10px' : '8px'}"
             src="/img/sdgmonitor-logo.svg"/>
      </template>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <template v-if="!hideMenuButton && !contributor">
      <hb-main-menu-button/>
    </template>

  </v-app-bar>
</template>

<script>
import TenantService from "@/service/tenant.service";

export default {
  name: "AppBar",
  props: {
    contributor: {
      type: Boolean,
      default: false,
    },
    hideMenuButton: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    planRoute() {
      return { name: 'plans', params: { tenantId: TenantService.tenant } };
    },
  },
}
</script>

<style scoped>

</style>
