<template>
  <div>
    <template v-if="point.top">
      <strong>{{ point.name }}</strong>
    </template>
    <template v-else>
      <strong>{{ point.parent }}, {{ point.name }}</strong>
    </template>
    <br />
    <div>{{ detailedImpactText }}</div>
    <br />
    <div>Amount of actions: {{ actionCount }}</div>
    <br />
    <strong>Actions</strong>
    <div v-for="action in actions" :key="action.id">
      {{ action.description }}, {{ action.indicatorCode }}
    </div>
    <div v-if="andMoreCount > 0" class="grey--text text--darken-1">
      And {{ andMoreCount }} more...
    </div>
  </div>
</template>

<script>

export default {
  props: ['chart', 'pieData'],
  mounted() {

  },
  computed: {
    actions() {
      if (this.pieData.point.actions == null) {
        return [];
      }
      return this.pieData.point.actions.slice(0, 10);
    },
    detailedImpactText() {
      const action = this.actions.length > 0 ? this.actions[0] : null;
      if (action == null) {
        return '';
      }

      return action.impactLongText;
    },
    point() {
      return this.pieData.point;
    },
    actionCount() {
      return this.pieData.point.value;
    },
    andMoreCount() {
      return this.pieData.point.value - 10;
    },
    esgText() {
      const esg = this.point.top ? this.point.name : this.point.parent;
      if (esg === 'Governance')
        return 'Help business to grow in a just and transparent way.';
      if (esg === 'Environment')
        return 'Help business to grow without harming the environment.';

      return 'Help business to grow while enhancing the social well-being.';
    }
  },
}
</script>

<style scoped>

</style>
