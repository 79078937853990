<template>
  <v-row>

    <v-col cols="12">
      <hb-heading :editable="editable" v-model="title"
                  @input="titleChanged"  :subtitle="$tc('dashboards.title', 1)"></hb-heading>
    </v-col>

    <v-col cols="12">
      <v-card class="sdg-card white rounded-0 fill-height" id="sdg-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton :needs-extra-space-on-top="true" v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#sdg-card'}" />
        <v-card-text class="sdg-card-text">
          <v-row>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.smAndDown ? '' : 'pt-10 pb-10 pl-10 pr-3'">
              <div class="sdg-heading-2">
                SDG{{ sdgIndex }}
              </div>
              <div class="sdg-subtitle mb-5">
                {{ $sdg(sdgIndex).goalText }}
              </div>
              <v-img max-height="100px" max-width="100px" :src="$getSDGImage(sdgIndex)"></v-img>
            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pt-10 pb-10 pl-3 pr-3'">
              <div class="sdg-subtitle">{{ $t('dashboards.sdg_business_indicator') }}</div>
              <v-row>
                <v-col cols="3" class="caption sdg-label pb-0">{{ $t('dashboards.code') }}</v-col>
                <v-col cols="5" class="caption sdg-label pb-0">{{ $tc('dashboards.indicator', 1) }}</v-col>
                <v-col cols="4" class="caption sdg-label pb-0">{{ $tc('dashboards.action', 1) }}</v-col>
              </v-row>
              <v-row v-for="action in currentSDGActions" :key="action.id" @click="linkToActionDashboard(action)" style="cursor: pointer">
                <v-col cols="3" class="body-1 py-0" >
                  {{ action.indicatorCode }}
                </v-col>
                <v-col cols="5" class="body-1 py-0">
                  {{ action.indicatorDescription }}
                </v-col>
                <v-col cols="4" class="body-1 py-0">
                  {{ action.description }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="text-md-center" :class="$vuetify.breakpoint.xsOnly ? '' : 'pt-10 pb-10 pl-3 pr-10'">
              <div class="sdg-subtitle">{{ $t('dashboards.number_of_actions') }}</div>
              <div class="number-bubble justify-center align-center">
                <div>{{ currentSDGActions.length }}</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-row align="stretch" justify="center">
        <v-col cols="12" md="6">
          <v-card class="sdg-card blue-border rounded-0 text-center" id="actions-per-sdg-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
            <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#actions-per-sdg-card'}" />
            <v-card-title class="sdg-card-title" style="display: block;">
              <div>
                <div class="sdg-subtitle">{{ $t('dashboards.actions_per_sdg') }}</div>
              </div>
            </v-card-title>
            <v-card-text class="text-left">
              <v-container fluid>
                <v-row>
                  <template v-for="sdg in Object.keys(actionsGroupedBySDGs)">
                    <total-actions-bar :key="sdg" :max-actions="maxActionsForSDG"
                                       :current-s-d-g-index="sdgIndex"
                                       :actions-count="actionsGroupedBySDGs[sdg].length" :sdg-index="sdg" />
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="sdg-card blue-border rounded-0 fill-height" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-10 pt-0'" id="all-sdgs-card" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
            <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#all-sdgs-card'}" />
            <v-card-text>
              <div class="sdg-heading-2">{{ $t('dashboards.all_sdgs') }}</div>
              <s-d-g-pie-chart :actions="actions" @sdg-clicked="sdgClicked"></s-d-g-pie-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>
  import _ from "lodash";
  import SDGPieChart from "./SDGPieChart";
  import TotalActionsBar from "./TotalActionsBar";
  import ExportImageButton from "@/components/ExportImageButton";

  export default {
    components: {ExportImageButton, TotalActionsBar, SDGPieChart },
    props: ['sdgIndex', 'plan', 'actions', 'settings', 'editable'],
    name: 'SDGDashboard',
    data() {
      return {
        title: '',
        showExportButtons: false,
      }
    },
    watch: {
      settings() {
        this.setTitle();
      },
    },
    methods: {
      linkToActionDashboard(action) {
        const routerData =  { name: 'action_dashboard', params: { actionId: action.id, planId: action.planId, tenantId: this.$tenant }};
        this.$router.push(routerData);
      },
      titleChanged() {//
        this.$emit('title-changed', this.title);
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = `${this.$t('dashboards.sdg')}${this.sdgIndex}`;
        } else {
          this.title = this.settings.title;
        }
      },
      sdgClicked(sdgIndex) {
        this.$emit('sdg-clicked', sdgIndex);
      },
    },
    computed: {
      currentSDGActions() {
        return this.actions.filter(a => a.sdgIndex === Number.parseInt(this.sdgIndex));
      },
      sdgCount() {
        const grouped = _.groupBy(this.actions, 'sdgIndex');
        return Object.keys(grouped).length;
      },
      actionsGroupedBySDGs() {
        return _.groupBy(this.actions, 'sdgIndex');
      },
      maxActionsForSDG() {
        let maxActions = 0;
        Object.keys(this.actionsGroupedBySDGs).forEach(key => {
          const sdgActionsLength = this.actionsGroupedBySDGs[key].length;
          if (sdgActionsLength > maxActions) {
            maxActions = sdgActionsLength;
          }
        })
        return maxActions;
      }
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style lang="scss" scoped>
  .number-bubble {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: #59949d;
    color: #ffffff;
    font-size: 42px;
    font-weight: bold;
    display: flex;
    margin: 10px 0;
  }

  @media screen and (min-width: 960px) {
    .number-bubble {
      margin: 10px auto;
    }
  }

  @media screen and (min-width: 1264px) {
    .number-bubble {
      width: 132px;
      height: 132px;
      font-size: 72px;
    }
  }
</style>
