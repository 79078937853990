<template>
  <v-row>
    <dashboard-card-element cols="12" md="6"
                            id="custom-impact-piechart"
                            :dashboard-code="settings.code"
                            :title="$t('dashboards.custom_impacts.piechart')"
                            :subtitle="rootImpact.text"
                            :editable="editable">
       <custom-impact-piechart :root-impact="rootImpact"
                               :dashboard-data="dashboardData"
                               :custom-impacts="customImpacts"></custom-impact-piechart>
    </dashboard-card-element>

    <dashboard-card-element cols="12" md="6"
                            id="custom-impact-sdgindex"
                            :dashboard-code="settings.code"
                            :title="$t('dashboards.custom_impacts.sdg_index')"
                            :subtitle="$t('dashboards.custom_impacts.sdg_index_subtitle')"
                            :editable="editable">
      <custom-impact-sdg-index :root-impact="rootImpact"
                               :dashboard-data="dashboardData"
                               :custom-impacts="customImpacts"></custom-impact-sdg-index>
    </dashboard-card-element>


    <dashboard-card-element cols="12"
                            v-if="hasDetailedImpacts"
                            id="custom-impact-treemap"
                            :dashboard-code="settings.code"
                            :title="$t('dashboards.custom_impacts.treemap')"
                            :editable="editable">
      <custom-impact-treemap :root-impact="rootImpact"
                             :dashboard-data="dashboardData"
                             :custom-impacts="customImpacts"></custom-impact-treemap>
    </dashboard-card-element>

    <dashboard-card-element cols="12"
                            id="custom-impact-action-list"
                            :dashboard-code="settings.code"
                            :title="$t('dashboards.custom_impacts.actions_list')"
                            :editable="editable">
      <custom-impact-action-list :root-impact="rootImpact"
                                 :dashboard-data="dashboardData"
                                 :custom-impacts="customImpacts"></custom-impact-action-list>
    </dashboard-card-element>
  </v-row>
</template>

<script>
import DashboardCardElement from "@/components/DashboardCardElement";
import CustomImpactPiechart from "@/components/CustomImpactDashboard/CustomImpactPiechart";
import CustomImpactSdgIndex from "@/components/CustomImpactDashboard/CustomImpactSdgIndex";
import CustomImpactActionList from "@/components/CustomImpactDashboard/CustomImpactActionList";
import CustomImpactTreemap from "@/components/CustomImpactDashboard/CustomImpactTreemap";


export default {
  props: ['dashboardData', 'customImpacts', 'settings', 'editable', 'rootImpact'],
  name: 'CustomImpactDashboardTab',
  components: { CustomImpactTreemap, CustomImpactActionList, CustomImpactSdgIndex, CustomImpactPiechart, DashboardCardElement },
  data() {
    return {
      showExportButtons: false,
    }
  },
  computed: {
    hasDetailedImpacts() {
      return this.customImpacts.find(ci => ci.root === this.rootImpact.id && ci.level === 2);
    },
  },
}
</script>

<style scoped>

</style>
