<template>
  <div>
    <div>{{ pointDate }}</div>
    <div><strong>{{ action.description }}: {{ value }} %</strong></div>
    <br />
    <p>{{ notes }}</p>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: ['pointData', 'chart'],
  name: 'ProgressChartTooltip',
  computed: {
    notes() {
      return this.point.action.notes;
    },
    value() {
      return this.point.y;
    },
    pointDate() {
      return dayjs(this.point.x).format("MMMM D, YYYY");
    },
    action() {
      return this.point.action;
    },
    point() {
      return this.pointData.point;
    }
  },
}
</script>

<style scoped>

</style>
