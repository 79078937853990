import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('div',{staticClass:"sdg-subtitle"},[_vm._v(_vm._s(_vm.$t('dashboards.custom_impacts.action_count'))+": "+_vm._s(_vm.actionsCount.length))])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VList,{staticClass:"py-0 px-0",attrs:{"color":"transparent"}},[(_vm.$vuetify.breakpoint.lgAndUp)?[_c(VListItem,{staticClass:"px-0",attrs:{"dense":""}},[_c(VListItemContent,{staticClass:"align-self-start align-items-start py-2"},[_c(VListItemSubtitle,{staticClass:"caption text-wrap"},[_c(VRow,{staticClass:"secondary--text",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":_vm.cols}},[_vm._v(" "+_vm._s(_vm.$tc('dashboards.action', 1))+" ")]),_c(VCol,{attrs:{"cols":_vm.cols}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.status'))+" ")]),_c(VCol,{attrs:{"cols":_vm.cols}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.custom_impacts.impact'))+" ")]),(_vm.hasDetailedImpacts)?_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.custom_impacts.detailed_impact'))+" ")]):_vm._e()],1)],1)],1)],1)]:_vm._e(),_vm._l((_vm.actionsFiltered),function(action){return _c(VListItem,{key:action.id,staticClass:"align-items-start px-0"},[_c(VListItemContent,{staticClass:"align-self-start align-items-start py-2"},[_c(VListItemSubtitle,{staticClass:"align-self-start text-wrap"},[_c('custom-impact-action-list-item',{attrs:{"root-impact":_vm.rootImpact,"action":action,"customImpacts":_vm.customImpacts}})],1)],1)],1)})],2)],1),_vm._l((_vm.actionsFiltered),function(action){return _c(VCol,{key:action.id,attrs:{"cols":"12","lg":"6"}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }