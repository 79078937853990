import { render, staticRenderFns } from "./SDGDashboard.vue?vue&type=template&id=3d0d82cd&scoped=true&"
import script from "./SDGDashboard.vue?vue&type=script&lang=js&"
export * from "./SDGDashboard.vue?vue&type=script&lang=js&"
import style0 from "./SDGDashboard.vue?vue&type=style&index=0&id=3d0d82cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0d82cd",
  null
  
)

export default component.exports