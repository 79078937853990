/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { hasNumericValue } from 'mathjs';
import i18n from "@/i18n";

const ValidationPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $rules() {
          return {
            required: (value) => value != null && (value + '').length > 0 || i18n.t('validation.field_is_required'),
            number: v =>  hasNumericValue(v) || i18n.t('validation.number_value'),
            email: email => {
              let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return !!re.test(String(email).toLowerCase()) || i18n.t('validation.email_is_required');
            },
            spacesNotAllowed: value => !(value && value.indexOf(" ") > -1) || i18n.t('validation.spaces_are_not_allowed'),
            passwordHasToMatch: (value, match) => value === match || i18n.t('user_profile.new_passwords_has_to_match'),
            mustBeAtLeast6Characters: value => !!value && value.length > 5 || i18n.t('user_profile.password_must_be_at_least_6_characters'),
          };
        },
      },
    });
  },
};

export default ValidationPlugin;
