<template>
  <v-row>
    <v-col cols="12">
      <hb-heading :editable="editable" v-model="title"
                  @input="titleChanged"
                  :subtitle="$tc('dashboards.title', 1)"></hb-heading>
    </v-col>

    <v-col cols="12" >
      <div id="impact-tree-map" style="position: relative;" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#impact-tree-map'}" />
        <ImpactTreeMap v-if="actions.length > 0" :actions="actions"></ImpactTreeMap>
      </div>
    </v-col>

    <v-col cols="12">
      <div id="impact-sdg-indicators" style="position: relative;" @mouseenter="showExportButtons = true" @mouseleave="showExportButtons = false">
        <ExportImageButton v-if="editable" :needsExtraSpaceOnTop="true" :show="showExportButtons" :options="{dashboardCode: this.settings.code, elementId: '#impact-sdg-indicators'}" />
        <ImpactSDGIndicatorsTable :actionsGroupedBySdgs="actionsGroupedBySDGs"></ImpactSDGIndicatorsTable>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import _ from "lodash";
  import ImpactTreeMap from "../Charts/ImpactTreeMap";
  import ImpactSDGIndicatorsTable from "@/components/ImpactDashboard/ImpactSDGIndicatorsTable";
  import ExportImageButton from "@/components/ExportImageButton";

  export default {
    components: {ExportImageButton, ImpactSDGIndicatorsTable, ImpactTreeMap },
    props: ['plan', 'actions', 'settings', 'editable'],
    name: 'ImpactDashboard',
    data() {
      return {
        title: '',
        showExportButtons: false,
      }
    },
    watch: {
      settings() {
        this.setTitle();
      },
    },
    methods: {
      titleChanged() {
        this.$emit('title-changed', this.title);
      },
      setTitle() {
        if (this.settings.title == null || this.settings.title.length === 0) {
          this.title = this.$t('dashboards.impacts');
        } else {
          this.title = this.settings.title;
        }
      },
    },
    computed: {
      actionsGroupedBySDGs() {
        return  _.groupBy(this.actions, 'sdgIndex');
      },
      sdgCount() {
        const grouped = _.groupBy(this.actions, 'sdgIndex');
        return Object.keys(grouped).length;
      },
      actionsGroupedByImpacts() {
        return _.groupBy(this.actions, 'impactText');
      },
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style scoped>

</style>
