<template>
  <div class="sdg-heading">
    <span :contenteditable="editable"
          @blur="onBlur"
          ref="editable" />
    <div v-if="subtitle.length > 0" class="sdg-subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
  export default {
    name: "EditableHeading",
    props: {
      value: {
        type: String,
        default: '',
      },
      editable: {
        type: Boolean,
        default: false,
      },
      subtitle: {
        type: String,
        default: '',
      },
    },
    watch: {
      value(newValue) {
        this.$refs.editable.innerText = newValue;
      },
    },
    mounted() {
      this.$refs.editable.innerText = this.value;
    },
    methods: {
      onBlur(e) {
        this.$emit('input', e.target.innerText);
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sdg-heading {
    color: #1b3548;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 15px;
    overflow: hidden;
    font-size: 2rem !important;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 1px;
    background: url("../../public/img/headerline.png") no-repeat center;
    // background: linear-gradient(transparent, transparent), url("../../public/img/headerline.svg") no-repeat center;
    background-position: bottom;
    background-size: 238px 10px;

    [contenteditable] {
      outline: 0px solid transparent;
    }

    span {
      display: inline-block;
    }

    .sdg-subtitle {
      font-weight: 300;
      font-size: 90%;
      color: #59949d;
    }
  }

  @media screen and (min-width: 960px) {
    .sdg-heading {
      font-size: 2.5rem !important;
      line-height: 2.725rem;
      background: none;
      padding: 0 100px;
      margin-bottom: 25px;

      span {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background: url("../../public/img/headerline.png");
          // background: linear-gradient(transparent, transparent), url("../../public/img/headerline.svg");
          background-size: 238px 10px;
          height: 10px;
          width: 238px;
        }

        &:before {
          left: -268px;
          top: 21px;
        }

        &:after {
          right: -268px;
          top: 21px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .sdg-heading {
      margin-top: 25px;
      font-size: 3rem !important;
      line-height: 3.125rem;

      span {
        &:before {
          top: 26px;
        }

        &:after {
          top: 26px;
        }
      }
    }
  }

  @media screen and (min-width: 1980px) {
    .sdg-heading {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  @media only print {
    .sdg-heading {
      font-size: 2.5rem !important;
      line-height: 2.725rem;
      background: none;
      padding: 0 100px;
      margin-bottom: 0;

      span {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-size: 238px 10px;
          height: 10px;
          width: 238px;
        }

        &:before {
          left: -268px;
          top: 21px;
        }

        &:after {
          right: -268px;
          top: 21px;
        }
      }
    }
  }
</style>
