import axios from 'axios';

/*
 * Used for api calls for current user.
 * for example: profile, get my tenants, ...
 */
const userApi = {

  async createSubscription(newSubscription) {
    await axios.post('/api/user/subscription', newSubscription);
  },
  async enableNotifications() {
    await axios.post('/api/user/subscription/enable');
  },
  async disableNotifications() {
    await axios.post('/api/user/subscription/disable');
  },
  async getMe() {
    const response = await axios.get('/api/user');
    return response.data;
  },
  /**
   *
   * @param {!Object} data
   * @param {!String} data.email
   * @param {!String} data.firstName
   * @param {!String} data.lastName
   * @returns {Promise<Object>}
   */
  async updateProfile(data) {
    const response = await axios.post('/api/user', data);
    return response.data;
  },

  /**
   *
   * @param {!Object} data
   * @param {!String} data.name
   * @param {!String} data.streetAddress
   * @param {!String} data.city
   * @param {!String} data.zipCode
   * @param {!Number} data.country
   * @param {!Number} data.business
   * @param {!Number} data.headcount
   * @param {!Number} data.turnover
   * @param {!String} data.priceId
   * @param {Boolean} data.copySamplePlan
   * @returns {Promise<any>}
   */
  async createTenant(data) {
    data.copySamplePlan = true;
    const response = await axios.post('/api/user/tenant', data);
    return response.data;
  },
  async acceptTos() {
    const response = await axios.post('/api/user/accept_tos');
    return response.data;
  }
};

export default userApi;
