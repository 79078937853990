<template>
    <div id="impactTreeMapContainer"></div>
</template>

<script>
  import _ from "lodash";
  import Vue from 'vue';
  import Highcharts from "highcharts";
  import TreeMap from 'highcharts/modules/treemap';

  import params from "../../config/params";
  import ImpactTreeMapTooltip from "@/components/Charts/ImpactTreeMapTooltip";

  TreeMap(Highcharts);

  export default {
    props: ['actions'],
    name: 'ImpactTreeMap',
    data() {
      return {
        chart: null,
      }
    },
    watch: {
      actions() {
        this.createChart();
      },
    },
    methods: {
      tooltipFormatter(data, chart) {
        const ComponentClass = Vue.extend(ImpactTreeMapTooltip);
        const instance = new ComponentClass({ propsData: { pieData: data, chart } });
        const mountedInstance = instance.$mount();
        const html = mountedInstance.$el.innerHTML;
        return html;
      },
      createChart() {
        const data = params.esgs.map(e => {
          return {
            id: e.text,
            name: e.text,
            top: true,
            color: e.color,
          }
        });

        Object.keys(this.actionsGroupedByESG).forEach(esgText => {
          const esgActions = this.actionsGroupedByESG[esgText];
          const byImpacts = _.groupBy(esgActions, 'impactText');
          Object.keys(byImpacts).forEach(impactText => {
            const first = byImpacts[impactText][0];
            data.push({
              name: impactText,
              longText: first.impactLongText,
              parent: esgText,
              top: false,
              value: byImpacts[impactText].length,
              actions: byImpacts[impactText],
            });
          });
        });

        const that = this;
        const chart = Highcharts.chart('impactTreeMapContainer', {
          chart: {
            style: {
              fontFamily: 'forma-djr-banner'
            },
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
          },
          title: {
            text: '',
          },
          xAxis: {
          },
          tooltip: {
            formatter: function(chart) {
              return that.tooltipFormatter(this, chart);
            },
            useHTML: true,
          },
          credits: false,
          series: [{
            type: "treemap",
            layoutAlgorithm: 'stripes',
            alternateStartingDirection: true,
            dataLabels: {
              style: {
                fontSize: '15px',
                textOutline: 0,
                fontFamily: 'forma-djr-banner',
                fontWeight: 'normal',
              },
            },
            levels: [{
              level: 1,
              layoutAlgorithm: 'sliceAndDice',
              dataLabels: {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                style: {
                  fontSize: '16px',
                  textOutline: 0,
                  fontWeight: 'bold',
                  fontFamily: 'forma-djr-banner'
                },
              },
            }],
            data,
          }],
        });

        this.chart = chart;
      },
    },
    computed: {
      sdgCount() {
        const grouped = _.groupBy(this.actions, 'sdgIndex');
        return Object.keys(grouped).length;
      },
      actionsGroupedByImpacts() {
        return _.groupBy(this.actions, 'impactText');
      },
      actionsGroupedByESG() {
        return _.groupBy(this.actions, 'esg');
      },
    },
    mounted() {
      this.createChart();
    }
  }
</script>

<style scoped>

</style>
