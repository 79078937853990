<template>
  <v-combobox v-bind="$attrs" :items="items" v-model="innerValue">

  </v-combobox>
</template>

<script>
export default {
  props: ['value'],
  name: 'DataPointSourceCombobox',
  data() {
    return {
      innerValue: this.value
    }
  },
  computed: {
    items() {
      return [
        { header: 'Select or type' },
        {
          text: 'Manual',
        },
        {
          text: 'API',
        },
        {
          text: 'File',
        }
      ]
    }
  },
  methods: {
    moro() {
      console.log('moro')
    }
  },
  watch: {
    value(to) {
      this.innerValue = to;
    },
    innerValue(to) {
      console.log('innervalue to')
      if (to === this.value) {
        return;
      }

      if (!to) {
        this.$emit('input', null);
      }

      if (to.text) {
        this.$emit('input', to.text);
      } else {
        this.$emit('input', to);
      }
    }
  }
}
</script>

<style scoped>

</style>
