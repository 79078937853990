<template>
  <v-col cols="12" sm="6">
    <v-card class="rounded-r-pill card-border" elevation="0" :style="{borderColor: $sdg(sdgIndex).color}"
            :to="toSDG">
      <v-card-text class="pa-0">
        <v-row no-gutters align="stretch">
          <v-col cols="6" class="pa-3">
            <v-row class="fill-height ma-0 sdg-title" align="center">
              <div>SDG{{ sdgIndex }}</div>
            </v-row>
          </v-col>
          <v-col cols="6" class="action-count text-right">
            <v-row class="fill-height ma-0" align="center" justify="end">
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="20"
                :value="actionsPercent" :color="$sdg(this.sdgIndex).color+'70'">
                <span class="action-count" :style="{color: $sdg(sdgIndex).color}">{{ actionsCount }}</span>
              </v-progress-circular>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  import * as math from 'mathjs';

  export default {
    name: "TotalActionsBar",
    props: ['maxActions', 'actionsCount', 'sdgIndex', 'color', 'currentSDGIndex'],
    computed: {
      showSDGLink() {
        return this.sdgIndex !== this.currentSDGIndex;
      },
      planId() {
        return this.$router.currentRoute.params.planId;
      },
      toSDG() {
        if (this.$router == null) {
          return null;
        }
        if (!this.showSDGLink) {
          return null;
        }

        return { name: 'sdg_dashboard', params: { sdgIndex: this.sdgIndex, tenantId: this.$tenant, planId: this.planId }};
      },
      actionsPercent() {
        return math.multiply(math.divide(this.actionsCount, this.maxActions), 100);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .action-count {
    font-size: 38px;
    font-weight: 600;
    color: #1b3548;
  }

  .card-border {
    border-left: 8px solid #ddd;
  }

  .sdg-title {
    font-size: 28px;
    font-weight: 500;
  }

  @media only screen and (min-width: 1264px) {
    .sdg-title {
      font-size: 38px;
    }
  }
</style>
