<template>
  <v-row dense>
    <!-- Large screens -->
    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-col :cols="cols">
        <strong>{{ action.description }}</strong>
      </v-col>
      <v-col :cols="cols">
        <span :class="statusTextColor">{{ action.containsData ? $t('plan.action_measuring_started') : $t('plan.action_no_data_yet') }}</span>
      </v-col>
      <v-col :cols="cols">
        {{ impactText }}
      </v-col>
      <v-col v-if="hasDetailedImpacts" cols="cols">
        {{ detailedImpactText }}
      </v-col>
    </template>

    <!-- Small screens -->
    <template v-if="$vuetify.breakpoint.xsOnly">
      <v-col cols="12" class="font-weight-medium pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $tc('dashboards.action', 1) }}
        </div>
        {{ action.description }}
      </v-col>
      <v-col cols="12" sm="12" class="caption pa-1 grey--text" style="line-height: 1rem;">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.status') }}
        </div>
        <span :class="statusTextColor">{{ action.containsData ? $t('plan.action_measuring_started') : $t('plan.action_no_data_yet') }}</span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.custom_impacts.impact') }}
        </div>
        {{ impactText }}
      </v-col>
      <v-col cols="12" class="pa-1">
        <div class="caption secondary--text font-weight-bold">
          {{ $t('dashboards.custom_impacts.detailed_impact')}}
        </div>
        {{ detailedImpactText }}
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  props: ['action', 'customImpacts', 'rootImpact'],
  name: 'CustomImpactActionListItem',
  computed: {
    cols() {
      return this.hasDetailedImpacts ? 3 : 4;
    },
    hasDetailedImpacts() {
      return this.customImpacts.find(ci => ci.root === this.rootImpact.id && ci.level === 2);
    },
    actionImpactSpec() {
      const allActionImpacts = this.action.customImpacts;
      const mapped = allActionImpacts.map(aci => this.customImpacts.find(ciSpec => ciSpec.id === aci.id)).filter(ci => ci.root === this.rootImpact.id);
      if (mapped.length === 1) {
        return mapped[0];
      }
      return null;
    },
    maxLevel() {
      return this.actionImpactSpec.level;
    },
    impactText() {
      if (this.maxLevel === 1) {
        return this.actionImpactSpec.text;
      } else { // Jos taso on 2
        return this.customImpacts.find(ciSpec => ciSpec.id === this.actionImpactSpec.parentId).text;
      }
    },
    detailedImpactText() {
      if (this.maxLevel === 1) {
        return '';
      } else { // Jos taso on 2
        return this.actionImpactSpec.text;
      }
    },
    statusTextColor() {
      return this.action.containsData ? 'green--text' : 'red--text';
    },
  },

}
</script>

<style scoped>

</style>
