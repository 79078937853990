<template>
  <div ref="customImpactSDGIndexChart"></div>
</template>

<script>
import _ from "lodash";
import { mean, round } from 'mathjs';
import Highcharts from "highcharts";
import DataService from "@/service/data.service";

export default {
  props: ['rootImpact', 'customImpacts', 'dashboardData'],
  name: "CustomImpactSdgIndex",
  computed: {
    actionsGroupedByCustomImpact() {
      const actions = this.dashboardData.actions;

      const actionsGroupedByCustomImpact = {};
      actions.forEach(a => {
        const actionCustomImpactSpecs = a.customImpacts.map(aci => this.customImpacts.find(ciSpec => aci.id === ciSpec.id));
        const correctCISpec = actionCustomImpactSpecs.find(ci => ci.root === this.rootImpact.id);
        if (correctCISpec) {
          const parent = this.customImpacts.find(ciSpec => ciSpec.id === correctCISpec.parentId);
          if (!(parent.text in actionsGroupedByCustomImpact)) {
            actionsGroupedByCustomImpact[parent.text] = [];
          }
          actionsGroupedByCustomImpact[parent.text].push({ action: a, ciSpec: correctCISpec, parent });
        }
      })

      return actionsGroupedByCustomImpact;
    }
  },
  methods: {
    createChart() {
      const firstLevelCustomImpacts = this.customImpacts.filter(ci => ci.parentId === this.rootImpact.id);
      const categories = firstLevelCustomImpacts.map(ci => ci.text);
      const maxLevel = _.max(this.customImpacts.filter(ci => ci.root === this.rootImpact.id).map(ci => ci.level));

      const data = [];
      firstLevelCustomImpacts.forEach(ci => {
        const actions = [];

        this.dashboardData.actions.forEach(a => {
          const currentRootActionCustomImpact = a.customImpacts.map(aci => this.customImpacts.find(ciSpec => ciSpec.id === aci.id)).find(aci => aci.root === this.rootImpact.id);
          if (!currentRootActionCustomImpact) {
            return;
          }

          let firstLevelImpactId;
          if (maxLevel === 1) {
            firstLevelImpactId = currentRootActionCustomImpact.id;
          } else if (maxLevel === 2) {
            firstLevelImpactId = currentRootActionCustomImpact.parentId;
          }

          if (!firstLevelImpactId) {
            return;
          }

          const firstLevelImpact = this.customImpacts.find(ciSpec => ciSpec.id === firstLevelImpactId);
          if (firstLevelImpact.id === ci.id) {
            actions.push(a);
          }
        });

        const datas = actions.filter(a => a.containsData).map(a => round(DataService.calculateSDGIndexForValue(a, a.currentValue.value) * 100));
        if (datas.length > 0) {
          data.push({ y: round(mean(datas), 1), color: ci.color });
        } else {
          data.push({ y: 0, color: ci.color });
        }
      });

      Highcharts.chart(this.$refs.customImpactSDGIndexChart, {
        chart: {
          type: 'bar',
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'forma-djr-banner, Tahoma',
          },
        },

        title: {
          text: null,
        },
        xAxis: {
          categories,
          title: {
            text: null
          },
          labels: {
            enabled: true,
          }
        },
        yAxis: {
          title: {
            text: null
          },
          min: 0,
          max: 100,
          labels: {
            enabled: true,
          }
        },
        tooltip: {
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: "#000",
          },
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false,
        },
        credits: {
          enabled: false
        },
        series: [{
          name: this.$t('dashboards.custom_impacts.average_sdg_index'),
          data,
        }],
      });
    }
  },
  mounted() {
    if (this.dashboardData.actions.length > 0) {
      this.$nextTick(() => {
        this.createChart();
      });
    }
  }
}
</script>

<style scoped>

</style>
