import axios from 'axios';

const instance = axios.create();

const publicApi = {
  async dashboardData(tenantId, uuid) {
    const response = await instance.get(`/api/shared/dashboard/${tenantId}/${uuid}`);
    return response.data;
  },

  async contributorInfo(tenantId, uuid) {
    const response = await instance.get(`/api/shared/contributor/${tenantId}/${uuid}`);
    return response.data;
  },

  /**
   *
   * @param tenantId
   * @param uuid
   * @param {Object} data
   * @param {Number} data.actionId
   * @param {Object} data.dataPointRequest
   * @param {Number} data.dataPointRequest.value
   * @param {Date} data.dataPointRequest.date
   * @returns {Promise<void>}
   */
  async addContributorData(tenantId, uuid, data) {
    const response = await instance.post(`/api/shared/contributor/${tenantId}/${uuid}/data`, data);
    return response.data;
  },

  async contributorUploadFile(tenantId, uuid, id, file, filename, info = {}) {
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('info', JSON.stringify(info));
    const response = await axios.post(`/api/shared/contributor/${tenantId}/${uuid}/data/${id}/file`, formData /*, config*/);
    return response.data;
  },
};

export default publicApi;
